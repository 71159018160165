import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography, styled } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import Transportation_Pipeline1 from './Transportation_Pipeline1';
import Transportation_Pipeline3 from './Transportation_Pipeline3';
import Certificate1 from '../components/Dialog/Certificate1';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Endpoints } from '../Enviornment/Endpoints';
import { setProduction } from '../Redux/Slices/ProductionCreationSlice';
export default function Transportation_Pipeline2(props: any) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    console.log(props)
    const [showVlccPipelineStep2, setShowVlccPipelineStep2] = useState<any>(false);
    const [selectedItem, setSelectedItem] = useState(props.step);
    const [open, setOpen] = React.useState(false);
    const [next, setNext] = useState(false)
    const [sendData, setSendData] = useState<any>()
    const productionData = useSelector((state: any) => state.Production)
    let senddata: any;
    const [rerender, setRerender] = useState(0);
    const [loader, setLoader] = useState(false);
    const [countries, setCountries] = useState<any>([])
    const [fuelTypeList, setFuelTypeList] = useState<any>([])
    const [originCountries, setOriginCountries] = useState<any>([])
    const [pipelineData, setPipelineData] = useState<any>([]);
    const Roaddata: any = {
        pipelineInfo: props.senddata.pipelineInfo,
    }
    const handleChange = (event: SelectChangeEvent) => {
        setSelectedItem(event.target.value as string);
    };
    const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
        mode: "onChange",
        defaultValues: {
            roadInfo: [
                {
                    transferLiter: '',
                    origin: '',
                    destination: '',
                    travelDistance: '',
                    ageOfTanker: '',
                    tankerWeight: '',
                    fuelType: '',
                    bulkStgTransID: ''
                }
            ],

        },
    });
    const { fields: roadInfoFields, append: appendRoadInfo, remove: removeRoadInfo } = useFieldArray({
        name: "roadInfo",
        control,
        rules: {
            required: "Enter All Details",
        },
    });
    const createData = (data: any) => {
        if (next == true) {
            senddata = data;
            console.log("send daat", senddata);
            nextForm();
        }
        else {
            setOpen(true)
        }
    }
    const resetForm = () => {

    }
    const addPipelineInfo = (index: any) => {
        appendRoadInfo({
            transferLiter: '',
            origin: '',
            destination: '',
            travelDistance: '',
            ageOfTanker: '',
            tankerWeight: '',
            fuelType: '',
            bulkStgTransID: ''
        })
    }
    const removedPipelineInfo = (index: any) => {
        removeRoadInfo(index)
    }
    const nextForm = () => {
        console.log("89899444444444", Roaddata);
        Roaddata.roadInfo = senddata.roadInfo;
        console.log("89899", Roaddata);
        console.log("123456789098765", senddata);
        setSendData(Roaddata)

        const sumOfTransferLiter = senddata.roadInfo.reduce((sum: any, item: any) => {
            return sum + parseInt(item.transferLiter, 10);
        }, 0);
        const afterSum = JSON.parse(productionData.refinedOutput) - sumOfTransferLiter
        console.log(productionData.refinedOutput,sumOfTransferLiter,afterSum,"after sum")
        if (JSON.parse(productionData.refinedOutput) < sumOfTransferLiter) {
            alert("error")
        } else {
            setShowVlccPipelineStep2(true);
            setSelectedItem('option3')
            dispatch(
                setProduction({
                    productionId: productionData.productionId,
                    refinedOutput: afterSum
                })
            );
        }
    }
    const backForm = () => {

        setSelectedItem('option1')
    }
    const closeDialog = () => {
        setOpen(false)
    }
    const getCountries = () => {
        setLoader(true)
        axios.get(Endpoints.Get_Country).then((res: any) => {
            setLoader(false)
            console.log(res.data, "res")
            setCountries(res.data)
        })
        let countrie: any = sessionStorage.getItem('countries')
        let d = JSON.parse(countrie);
        let country: any = []
        let origin = () => d.map((data: any) => country.push(data.destination))
        origin()
        country = country.filter((value: any, index: any, self: any) => self.indexOf(value) === index);
        console.log(country)
        setOriginCountries(country)

    }
    const getFuelType = () => {
        axios.get(Endpoints.Get_Fuel_Type).then((res: any) => {
            setFuelTypeList(res.data[0].value)
        })
    }
    const handleChangeCountry = (e: any) => {
    }
    useEffect(() => {
        setRerender(rerender + 1)
        getCountries()
        getFuelType()
    }, [])
    return (
        <div>
            {selectedItem === 'option2' ?
                <Grid container spacing={2}>
                    <Grid item xs={4} className='grid_content'>
                        <img src={require('../asserts/Realgreen-Imgs/Screen7.png')} alt="Screen" className='screen_img' />
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs sx={{ pt: '0px !important' }}>
                        <Grid container spacing={2} >
                            <Grid item xs={3} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refinery Id:</Typography>&nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{productionData.identityId}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Production Id:</Typography>&nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{productionData.productionId}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refined Output:</Typography> &nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{parseFloat(productionData.refinedOutput).toLocaleString('en-IN')} Ltrs.</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth size='small'>
                                    {/* <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                    <Select value={props.steps === undefined ? selectedItem : 'option3'} onChange={handleChange} labelId="demo-simple-select-label"
                                        id="demo-simple-select" label="Select">
                                        <MenuItem value="option1">Pipeline</MenuItem>
                                        <MenuItem value="option2">Road</MenuItem>
                                        <MenuItem value="option3">Railway</MenuItem>
                                    </Select> */}
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <FormControlLabel control={<Checkbox />} label="Pipeline" />
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Road" />
                                        <FormControlLabel control={<Checkbox />} label="Railway" />
                                    </div>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Box>
                            <form onSubmit={handleSubmit((data: any) => {
                                createData(data);
                            })}>
                                <Box sx={{ mb: 1, mt: 2 }}>
                                    <Typography className="grid_title">Road Info</Typography>
                                    {roadInfoFields.map((field: any, index: any) => (
                                        <Paper sx={{ p: 2, my: 2 }}>
                                            <Grid container spacing={2} key={index} sx={{ my: 1 }}>
                                                <Grid item xs={10}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Transporter ID / Carrier ID" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`roadInfo.${index}.bulkStgTransID`)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Transfer Litres" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`roadInfo.${index}.transferLiter`)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Autocomplete
                                                                key={rerender}
                                                                size="small"
                                                                options={originCountries}
                                                                getOptionLabel={(option: any) => option}
                                                                onChange={(event, newValue) => {
                                                                    handleChangeCountry(newValue)
                                                                }}

                                                                renderInput={(params) => <TextField {...params}  {...register(`roadInfo.${index}.origin`,)} label="Origin" />}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Autocomplete
                                                                key={rerender}
                                                                size="small"
                                                                options={countries}
                                                                getOptionLabel={(option: any) => option.countryName}
                                                                onChange={(event, newValue) => {
                                                                    handleChangeCountry(newValue)
                                                                }}

                                                                renderInput={(params) => <TextField {...params}  {...register(`roadInfo.${index}.destination`)} label="Destination" />}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Travel Distance" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`roadInfo.${index}.travelDistance`)}

                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start"> kms.</InputAdornment>,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Age of Tanker" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`roadInfo.${index}.ageOfTanker`)}

                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start"> Yrs.</InputAdornment>,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Tanker Weight" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`roadInfo.${index}.tankerWeight`)}

                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start"> Tons.</InputAdornment>,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Autocomplete
                                                                key={rerender}
                                                                size="small"
                                                                options={fuelTypeList}
                                                                getOptionLabel={(option: any) => option}
                                                                onChange={(event, newValue) => {

                                                                }}

                                                                renderInput={(params) => <TextField {...params}  {...register(`roadInfo.${index}.fuelType`)} label="Fuel Type" />}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2} sx={{ margin: 'auto', textAlign: 'center' }}>
                                                    {index == 0 ? <IconButton onClick={() => addPipelineInfo(index)}><AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} /></IconButton> : null}
                                                    {index == 0 ? null : <IconButton onClick={() => removedPipelineInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                    ))}
                                </Box>
                                {props.steps === undefined ?
                                    <Box display="flex" justifyContent="center" alignItems="center">


                                        {/* <LoadingButton
                                            variant="contained"
                                            size="small"
                                            type="submit"
                                            loading={false}
                                            color='primary'
                                            sx={{ marginLeft: 2 }} // Add margin-left for space
                                            onClick={backForm}
                                        >
                                            Back
                                        </LoadingButton> */}
                                        <LoadingButton
                                            variant="contained"
                                            size="small"
                                            type="submit"
                                            loading={false}
                                            color='success'
                                            sx={{ marginLeft: 2 }} // Add margin-left for space
                                            onClick={() => setNext(true)}
                                        >
                                            Next
                                        </LoadingButton>
                                        <LoadingButton
                                            variant="contained"
                                            size="small"
                                            type="submit"
                                            loading={false}
                                            color='primary'
                                            sx={{ marginLeft: 2 }} // Add margin-left for space
                                            onClick={() => { setSelectedItem("option3"); setSendData({ 'roadInfo': [] }) }}
                                        >
                                            Skip
                                        </LoadingButton>

                                    </Box> : <Box display="flex" justifyContent="end" alignItems="center">
                                        <LoadingButton
                                            loading={false}
                                            variant="contained"
                                            type="submit"
                                            className="submitbtn"
                                            size='small'
                                            color='success'
                                            sx={{

                                                fontWeight: 500,
                                                // backgroundColor: "#555a64",
                                                // color: "#fff",
                                                float: 'right',
                                                "& .MuiButtonBase-root:hover": {
                                                    backgroundColor: "#555a64",
                                                    color: "#fff",
                                                },
                                            }}
                                        // onClick={nextForm}
                                        >
                                            Next
                                        </LoadingButton>
                                    </Box>}
                            </form>
                        </Box>

                    </Grid>
                </Grid> : null
            }
            {
                selectedItem == "option1" && <Transportation_Pipeline1 step={"option1"} />
            }
            {
                selectedItem == "option3" && <Transportation_Pipeline3 step={"option3"} data={Roaddata} senddata={sendData} />
            }



            <div className="custom-dialog">
                {open ? (
                    <Certificate1
                        onOpen={open}
                        onClose={closeDialog}
                        status={"open"}
                        nextStep={'Regional'}
                        sx={{
                            "& .MuiDialog-root": {
                                borderRadius: "100px",
                                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                                zIndex: "9999 !important",
                            },
                        }}
                    />
                ) : null}
            </div>
        </div>
    )
}
