import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Divider, FormControl, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Certificate1 from '../components/Dialog/Certificate1';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import moment from 'moment';
import { setStepper } from '../Redux/Slices/StepperSlice';
import axios from 'axios';
import { Endpoints } from '../Enviornment/Endpoints';

import { Controller } from 'react-hook-form';
import { setLogger } from 'react-query';
import { toast } from 'react-toastify';
import { setProduction } from '../Redux/Slices/ProductionCreationSlice';

export default function Regional_Distribution_Storage() {
  const dispatch = useDispatch()
  const bulkStorageData = useSelector((state: any) => state.BulkStorage)
  const certificateData = useSelector((state: any) => state.Certificate)
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const productionData = useSelector((state: any) => state.Production)
  const [energyTypeList, setEnergyTypeList] = useState<any>([])
  const [documnetdata, setDocumentData] = useState<any>()
  console.log(productionData,bulkStorageData)
  // const combinedArray:any = [...bulkStorageData.bulkInfo.pipeline, ...bulkStorageData.bulkInfo.vessel];
  console.log(bulkStorageData, "bulk storage")
  useEffect(() => {
    dispatch(setStepper(4))
    getEnergyTypeList()
    LoopData.filter((item:any,index:any)=>{
      console.log(item,"item")
      return setValue(`origin.${index}.origin`,item.dest)
    })
  }, [])
  const payload = {
    "productionID": "PR-001",
    "pipeline": [
      {
        "transferLiter": "270",
        "origin": "Hyderabad",
        "destination": "Warangal",
        "pipeDistance": "300",
        "avgFlowRate": "2",
        "avgTemperature": "50",
        "avgLeakage": "5"
      },
      {
        "transferLiter": "200",
        "origin": "Hyderabad",
        "destination": "Nalgonda",
        "pipeDistance": "200",
        "avgFlowRate": "2",
        "avgTemperature": "50",
        "avgLeakage": "5"
      }
    ],
    "road": [
      {
        "transferLiter": "100",
        "origin": "Hyderabad",
        "destination": "Hyderabad",
        "travelDistance": "20",
        "ageOfTanker": "2",
        "tankerWeight": "500",
        "fuelType": "petrol"
      },
      {
        "transferLiter": "100",
        "origin": "Hyderabad",
        "destination": "Hyderabad",
        "travelDistance": "30",
        "ageOfTanker": "2",
        "tankerWeight": "500",
        "fuelType": "diesel"
      }
    ],
    "railways": [
      {
        "transferLiter": "150",
        "origin": "Hyderabad",
        "destination": "Kurnool",
        "travelDistance": "300",
        "ageOfTrain": "2",
        "fuelType": "petrol"
      },
      {
        "transferLiter": "150",
        "origin": "Hyderabad",
        "destination": "Vijaywada",
        "travelDistance": "350",
        "ageOfTrain": "2",
        "fuelType": "diesel"
      }
    ],
    "createdBy": "Admin",
    "updatedBy": "",
    "createdAt": "22-11-2023",
    "updatedAt": "22-11-2023"
  }

  const getdestAndType = () => {
    let outerobj: any = []
    bulkStorageData.bulkInfo.pipeline.map((item: any, index: any) => {
      let obj = {
        dest: item.destination,
        type: 'pipeline'
      }
      outerobj.push(obj)
    })
    bulkStorageData.bulkInfo.road.map((item: any, index: any) => {
      let obj = {
        dest: item.destination,
        type: 'road'
      }
      outerobj.push(obj)
    })
    bulkStorageData.bulkInfo.railways.map((item: any, index: any) => {
      let obj = {
        dest: item.destination,
        type: 'railways'
      }
      outerobj.push(obj)
    })
    return outerobj;
  }
  let Loop = getdestAndType();


  // const uniquedets = Array.from(new Set(Loop.map((a: any, index: any) => a.dest)))
  //   .map(dest => {
  //     return Loop.find((a: any) => a.dest == dest)
  //   })

  // console.log(uniquedets, "uniquedets")

  const [LoopData] = useState<any>(Loop)

  const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
    mode: "onChange",
    defaultValues: {
      origin: [
        {
          storageLiters: '',
          storageTime: '',
          energyType: '',
          electricity: '',
          avgVOCsEvaporations: '',
          avgPressure: '',
          avgLeakage: '',
          regDistStgID: '',
          origin: ''
        }
      ],

    },
  });
  const { fields: storageInfoFields, append: appendStorageInfo, remove: removeStorageInfo } = useFieldArray({
    name: "origin",
    control,
    rules: {
      required: "Enter All Details",
    },
  });
  const getEnergyTypeList = () => {
    axios.get(Endpoints.Get_Energy_Type).then((res: any) => {
      setEnergyTypeList(res.data[0].value)
    })
  }
  const createData = (data: any) => {
    LoopData.filter((item: any, index: any) => {
      console.log(item, "item")
      return setValue(`origin.${index}.origin`, item.dest)
    })
    let payload = {
      productionID: productionData.productionId,
      bulkStgTransID: certificateData.bulkStgTransID,
      origin: data.origin,
      createdBy: "Admin",
      updatedBy: "",
      createdAt: moment().format('DD-MM-YYYY'),
      updatedAt: moment().format('DD-MM-YYYY')
    }
    console.log('payload is', data, payload)
    const sumOfTransferLiter = data.origin.reduce((sum: any, item: any) => {
      return sum + parseInt(item.storageLiters, 10);
    }, 0);
    if (sumOfTransferLiter === JSON.parse(productionData.refinedOutput)) {
      console.log("Sum of transferLiter values matches totRefOpAfterLeak");
      setLoader(true)
      axios.post(Endpoints.Create_Reg_Dist_Storage, payload).then((res: any) => {
        setLoader(false)
        if (res.data.status === "Success") {
          toast.success(res.data.message)
          dispatch(
            setProduction({
              productionId: res.data.data.regionalDistStorageInfo.productionID,
              refinedOutput: res.data.data.regionalDistStorageInfo.totRefOpAfterLeak,
              identityId: productionData.identityId,
              identityLocation: productionData.identityLocation
            })
          );
          sessionStorage.setItem('countries', JSON.stringify(data.origin))
          setDocumentData(res.data.data)
          setOpen(true)
        }
      })
    }
    else {
      alert("Sum of transferLiter values does not match totRefOpAfterLeak")
      console.log("Sum of transferLiter values does not match totRefOpAfterLeak", sumOfTransferLiter, typeof (JSON.parse(productionData.refinedOutput)));
    }

    // setOpen(true)
  }

  const resetForm = () => { }
  const closeDialog = () => {
    setOpen(false)
  }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4} className='grid_content'>
          <img src={require('../asserts/Realgreen-Imgs/Screen9.png')} alt="Screen" className='screen_img' />

        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs sx={{ pt: '0px !important' }}>
          <Grid container spacing={2}>
            <Grid item xs={3} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refinery Id:</Typography>&nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{productionData.identityId}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Production Id:</Typography>&nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{productionData.productionId}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refined Output:</Typography> &nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{parseFloat(productionData.refinedOutput).toLocaleString('en-IN')}&nbsp;Ltrs.</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item>
              <form onSubmit={handleSubmit((data: any) => {
                createData(data);
              })}>
                <Box sx={{ mb: 1 }}>

                  {LoopData.map((fields: any, index: any) => (
                    <Box key={index}>

                      <Typography className="grid_title">{fields.dest} - {fields.type.charAt(0).toUpperCase() + fields.type.slice(1)}</Typography>
                      <Grid container spacing={2} key={index} sx={{ my: 1 }}>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Regional Distribution ID" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.regDistStgID`)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Storage Litres" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.storageLiters`)}

                              InputProps={{
                                endAdornment: <InputAdornment position="start"> kg.</InputAdornment>,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Storage Time" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.storageTime`)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">Hrs.</InputAdornment>,
                              }}
                            />

                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Electricity" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.electricity`)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">KW.</InputAdornment>,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name={`origin.${index}.energyType`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Autocomplete
                                key={fields.id}
                                {...field}

                                size="small"
                                options={energyTypeList}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => field.onChange(newValue)}
                                renderInput={(params) => (
                                  <TextField {...params} label="Energy Type" />
                                )}
                              />
                            )}
                          />


                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Average VOCs Evaporations" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.avgVOCsEvaporations`)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">mg/m3.</InputAdornment>,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Average Pressure" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.avgPressure`)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">Pa.</InputAdornment>,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Average Leakage" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.avgLeakage`)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">Ltrs.</InputAdornment>,
                              }}
                            />
                          </FormControl>
                        </Grid>


                      </Grid>


                    </Box>

                  ))}
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <LoadingButton
                    loading={false}
                    variant="contained"
                    type="submit"
                    className="submitbtn"
                    size='small'
                    sx={{

                      fontWeight: 500,
                      backgroundColor: "#555a64",
                      color: "#fff",
                      "& .MuiButtonBase-root:hover": {
                        backgroundColor: "#555a64",
                        color: "#fff",
                      },
                    }}
                    onClick={resetForm}
                  >
                    Reset
                  </LoadingButton>

                  <LoadingButton
                    variant="contained"
                    size="small"
                    type="submit"
                    loading={loader}
                    color='success'
                    sx={{ marginLeft: 2 }} // Add margin-left for space
                  >
                    Preview
                  </LoadingButton>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="custom-dialog">
        {open ? (
          <Certificate1
            onOpen={open}
            onClose={closeDialog}
            status={"open"}
            currentStepCount={5}
            docData={documnetdata}
            key={3}
            nextStep={'Transportation1'}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </div>
  )
}
