import {combineReducers} from '@reduxjs/toolkit';
import AuthReducer from '../Slices/Authentication';
import StepperReducer from '../Slices/StepperSlice';
import ProductionReducer from '../Slices/ProductionCreationSlice';
import BulkStorageReducer from '../Slices/BulkStorageSlice';
import CertificateReducer from '../Slices/CertificateSlice'
const rootReducer = combineReducers({
    Auth:AuthReducer,
    Stepper:StepperReducer,
    Production:ProductionReducer,
    BulkStorage: BulkStorageReducer,
    Certificate: CertificateReducer

})

export default rootReducer;