import React from 'react'
import Transportation_Pipeline1 from './Transportation_Pipeline1'

export default function Transportation() {
  return (
    <div>
        <Transportation_Pipeline1 step={'option1'}/>
    </div>
  )
}
