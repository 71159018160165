import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography, styled, FormControlLabel, Checkbox } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import Transportation_Pipeline1 from './Transportation_Pipeline1';
import Certificate1 from '../components/Dialog/Certificate1';
import Transportation_Pipeline2 from './Transportation_Pipeline2';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Endpoints } from '../Enviornment/Endpoints';
import { setProduction } from '../Redux/Slices/ProductionCreationSlice';
import { setBulkData } from '../Redux/Slices/BulkStorageSlice';
export default function Transportation_Pipeline3(props: any) {
    const productionData = useSelector((state: any) => state.Production)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [showVlccPipelineStep2, setShowVlccPipelineStep2] = useState<any>(false);
    console.log("3 screen data", productionData)
    const [selectedItem, setSelectedItem] = useState(props.step);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [rerender, setRerender] = useState(0);
    const [countries, setCountries] = useState<any>([])
    const [fuelTypeList, setFuelTypeList] = useState<any>([])
    const [originCountries, setOriginCountries] = useState<any>([])
    const [documnetdata, setDocumentData] = useState<any>()
    const certificateData = useSelector((state: any) => state.Certificate)
    console.log(certificateData)
    const handleChange = (event: SelectChangeEvent) => {
        setSelectedItem(event.target.value as string);
    };
    const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
        mode: "onChange",
        defaultValues: {
            railInfo: [
                {
                    transferLiter: '',
                    origin: '',
                    destination: '',
                    travelDistance: '',
                    ageOfTrain: '',
                    fuelType: '',
                    bulkStgTransID: ''
                }
            ],

        },
    });
    const { fields: railInfoFields, append: appendRailInfo, remove: removeRailInfo } = useFieldArray({
        name: "railInfo",
        control,
        rules: {
            required: "Enter All Details",
        },
    });
    const createData = (data: any) => {
        let payload = {
            refineryID: productionData.identityId,
            pipeline: props.senddata.pipelineInfo,
            road: props.senddata.roadInfo,
            railways: data.railInfo,
            productionID: productionData.productionId,
            createdBy: "Admin",
            updatedBy: "",
            createdAt: moment().format('DD-MM-YYYY'),
            updatedAt: moment().format('DD-MM-YYYY'),
            bulkStorageID: certificateData.bulkStorageID

        }
        console.log(payload, "paload")

        setLoader(true)
        axios.post(Endpoints.Create_Bulk_TransPort, payload).then((res: any) => {
            setLoader(false)
            if (res.data.status === "Success") {
                toast.success(res.data.message)
                console.log(res.data)
                dispatch(
                    setBulkData({
                        bulkInfo: res.data.data.bulkStrgTransportation
                        // bulkInfo: res.data.data.vlccAndPipelineInfo
                    })
                );
                setDocumentData(res.data.data)
                setOpen(true)
                dispatch(
                    setProduction({
                        productionId: productionData.productionId,
                        refinedOutput: res.data.data.bulkStrgTransportation.totRefOpAfterLeak,
                        identityId: productionData.identityId
                    })
                );


            }
        })
            .catch((err) => console.log(err))
    }
    const resetForm = () => {

    }
    const addPipelineInfo = (index: any) => {
        appendRailInfo({
            transferLiter: '',
            origin: '',
            destination: '',
            travelDistance: '',
            ageOfTrain: '',
            fuelType: '',
            bulkStgTransID: ''
        })
    }
    const removedPipelineInfo = (index: any) => {
        removeRailInfo(index)
    }
    const nextForm = () => {
        setShowVlccPipelineStep2(true);
        setSelectedItem('option3')
    }
    const closeDialog = () => {
        setOpen(false)
    }
    const getFuelType = () => {
        axios.get(Endpoints.Get_Fuel_Type).then((res: any) => {
            setFuelTypeList(res.data[0].value)
        })
    }
    const getCountries = () => {
        setLoader(true)
        axios.get(Endpoints.Get_Country).then((res: any) => {
            setLoader(false)
            console.log(res.data, "res")
            setCountries(res.data)
        })
        let countrie: any = sessionStorage.getItem('countries')
        let d = JSON.parse(countrie);
        let country: any = []
        let origin = () => d.map((data: any) => country.push(data.destination))
        origin()
        country = country.filter((value: any, index: any, self: any) => self.indexOf(value) === index);
        setOriginCountries(country)

    }
    useEffect(() => {
        setValue(`railInfo.${0}.origin`, productionData.identityLocation)
        setRerender(rerender + 1)
        getCountries()
        getFuelType()
    }, [])
    return (
        <div>
            {selectedItem === 'option3' ?
                <Grid container spacing={2}>
                    <Grid item xs={4} className='grid_content'>
                        <img src={require('../asserts/Realgreen-Imgs/Screen8.png')} alt="Screen" className='screen_img' />
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs sx={{ pt: '0px !important' }}>
                        <Grid container spacing={2} >
                            <Grid item xs={3} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refinery Id:</Typography>&nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{productionData.identityId}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Production Id:</Typography>&nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{productionData.productionId}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refined Output:</Typography> &nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{parseFloat(productionData.refinedOutput).toLocaleString('en-IN')} Ltrs.</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth size='small'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <FormControlLabel control={<Checkbox />} label="Pipeline" />
                                        <FormControlLabel control={<Checkbox />} label="Road" />
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Railway" />
                                    </div>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Box>
                            <form onSubmit={handleSubmit((data: any) => {
                                createData(data);
                            })}>
                                <Box sx={{ mb: 1, mt: 2 }}>
                                    <Typography className="grid_title">Railway Info</Typography>
                                    {railInfoFields.map((field: any, index: any) => (
                                        <Paper sx={{ p: 2, my: 2 }}>
                                            <Grid container spacing={2} key={index} sx={{ my: 1 }}>
                                                <Grid item xs={10}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Transporter ID / Carrier ID" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`railInfo.${index}.bulkStgTransID`)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Transfer Litres" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`railInfo.${index}.transferLiter`)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Autocomplete
                                                                key={rerender}
                                                                size="small"
                                                                options={originCountries}
                                                                getOptionLabel={(option: any) => option}
                                                                onChange={(event, newValue) => {
                                                                }}

                                                                renderInput={(params) => <TextField {...params}  {...register(`railInfo.${index}.origin`,)} label="Origin" />}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Autocomplete
                                                                key={rerender}
                                                                size="small"
                                                                options={countries}
                                                                getOptionLabel={(option: any) => option.countryName}
                                                                onChange={(event, newValue) => {
                                                                }}

                                                                renderInput={(params) => <TextField {...params}  {...register(`railInfo.${index}.destination`,)} label="Destination" />}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Travel Distance" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`railInfo.${index}.travelDistance`)}

                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start"> kms.</InputAdornment>,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Age of Tanker" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`railInfo.${index}.ageOfTrain`)}

                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start"> Yrs.</InputAdornment>,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <Autocomplete
                                                                key={rerender}
                                                                size="small"
                                                                options={fuelTypeList}
                                                                getOptionLabel={(option: any) => option}
                                                                onChange={(event, newValue) => {

                                                                }}

                                                                renderInput={(params) => <TextField {...params}  {...register(`railInfo.${index}.fuelType`)} label="Fuel Type" />}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2} sx={{ margin: 'auto', textAlign: 'center' }}>
                                                    {index == 0 ? <IconButton onClick={() => addPipelineInfo(index)}><AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} /></IconButton> : null}
                                                    {index == 0 ? null : <IconButton onClick={() => removedPipelineInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                    ))}
                                </Box>
                                {props.steps === undefined ?
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <LoadingButton
                                            loading={false}
                                            variant="contained"
                                            type="submit"
                                            className="submitbtn"
                                            size='small'
                                            sx={{

                                                fontWeight: 500,
                                                backgroundColor: "#555a64",
                                                color: "#fff",
                                                "& .MuiButtonBase-root:hover": {
                                                    backgroundColor: "#555a64",
                                                    color: "#fff",
                                                },
                                            }}
                                            onClick={(event) => resetForm()}
                                        >
                                            Reset
                                        </LoadingButton>

                                        <LoadingButton
                                            variant="contained"
                                            size="small"
                                            type="submit"
                                            loading={loader}
                                            color='success'
                                            sx={{ marginLeft: 2 }} // Add margin-left for space
                                        >
                                            Preview
                                        </LoadingButton>
                                        {/* <LoadingButton
                                            variant="contained"
                                            size="small"
                                            type="submit"
                                            loading={false}
                                            color='primary'
                                            sx={{ marginLeft: 2 }} // Add margin-left for space
                                            onClick={() => setSelectedItem("option2")}
                                        >
                                            Back
                                        </LoadingButton> */}
                                        <LoadingButton
                                            variant="contained"
                                            size="small"
                                            type="submit"
                                            loading={false}
                                            color='primary'
                                            sx={{ marginLeft: 2 }} // Add margin-left for space
                                        >
                                            Save As Draft
                                        </LoadingButton>
                                    </Box> : <Box display="flex" justifyContent="end" alignItems="center">
                                        <LoadingButton
                                            loading={false}
                                            variant="contained"
                                            type="submit"
                                            className="submitbtn"
                                            size='small'
                                            color='primary'
                                            sx={{

                                                fontWeight: 500,
                                                // backgroundColor: "#555a64",
                                                // color: "#fff",
                                                float: 'right',
                                                "& .MuiButtonBase-root:hover": {
                                                    backgroundColor: "#555a64",
                                                    color: "#fff",
                                                },
                                            }}
                                            onClick={nextForm}
                                        >
                                            Next
                                        </LoadingButton>
                                    </Box>}
                            </form>
                        </Box>

                    </Grid>
                </Grid> : null}
            {
                selectedItem == "option2" && <Transportation_Pipeline2 step={"option2"} />
            }
            <div className="custom-dialog">
                {open == true ? (
                    <Certificate1
                        onOpen={open}
                        onClose={closeDialog}
                        status={"open"}
                        docData={documnetdata}
                        nextStep={'Regional'}
                        currentStepCount={4}
                        sx={{
                            "& .MuiDialog-root": {
                                borderRadius: "100px",
                                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                                zIndex: "9999 !important",
                            },
                        }}
                    />
                ) : null}
            </div>
        </div>
    )
}
