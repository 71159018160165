import { Typography } from '@mui/material'
import React from 'react'

export default function Footer() {
    return (
       <div className='footer'>
         <Typography>
            Copyright &#169; RealGreen - All Rights Reserved
        </Typography>
       </div>
    )
}
