import React from 'react'
import { useState, useEffect } from 'react'
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography, styled } from '@mui/material';
import axios from 'axios';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable, { TableColumn } from "react-data-table-component";
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import Certificate1 from '../components/Dialog/Certificate1';
import swal from 'sweetalert';
import { Endpoints } from '../Enviornment/Endpoints';
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7'
        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },

};
const ROOT_PATH = 'https://echarts.apache.org/examples';
type EChartsOption = echarts.EChartsOption;
export default function Emission_Tree() {
    const { state } = useLocation();
    const [open, setOpen] = React.useState(false);
    const [event, setEvent] = useState<string>("")
    const [currentStepCount, setcurrentStepCount] = useState<number>(0)
    // console.log(tableData)
    const [rowData, setRowData] = useState<any>([state.tableData]);
    const [documnetdata, setDocumentData] = useState<any>()
    const columns: TableColumn<any>[] = [
        {
            name: "PRODUCTION ID",
            selector: row => row.ProductionID !== '' && row.ProductionID !== null && row.ProductionID != undefined ? row.ProductionID : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px'
        },
        {
            name: "SCOPE1 CO2e IN MT ",
            selector: row => row.Scope1 !== '' && row.Scope1 !== null && row.Scope1 != undefined ? row.Scope1.toLocaleString('en-IN') : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        },
        {
            name: "SCOPE2 CO2e IN MT ",
            selector: row => row.Scope2 !== '' && row.Scope2 !== null && row.Scope2 != undefined ? row.Scope2.toLocaleString('en-IN') : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        },
        {
            name: "SCOPE3 CO2e IN MT ",
            selector: row => row.Scope3 !== '' && row.Scope3 !== null && row.Scope3 != undefined ? row.Scope3.toLocaleString('en-IN') : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        },
        {
            name: "TOTAL CO2e IN MT ",
            selector: row => row.Total !== '' && row.Total !== null && row.Total != undefined ? row.Total.toLocaleString('en-IN') : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        }
    ]
    let data: any = {
        "name": "Refineries",
        "children": [
            {
                "name": "VLCC",
                "children": [
                    {
                        "name": "Bulk Storage",
                        "children": [
                            {
                                "name": "Train",
                                "children": [
                                    {
                                        "name": "Regional Distribution & Storge",
                                        "children": [
                                            {
                                                "name": "Transportation",
                                                "children": [
                                                    {
                                                        "name": "Outlets",
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]
                            },
                            {
                                "name": "Tanker",
                                "children": [
                                    {
                                        "name": "Regional Distribution & Storge",
                                        "children": [
                                            {
                                                "name": "Transportation",
                                                "children": [
                                                    {
                                                        "name": "Outlets",
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]
                            },
                        ]
                    },
                ]
            },
            {
                "name": "Pipeline",
                "children": [
                    {
                        "name": "Bulk Storage", "children": [
                            {
                                "name": "Train",
                                "children": [
                                    {
                                        "name": "Regional Distribution & Storge",
                                        "children": [
                                            {
                                                "name": "Transportation",
                                                "children": [
                                                    {
                                                        "name": "Outlets",
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]
                            },
                            {
                                "name": "Tanker",
                                "children": [
                                    {
                                        "name": "Regional Distribution & Storge",
                                        "children": [
                                            {
                                                "name": "Transportation",
                                                "chidren": [
                                                    {
                                                        "name": "Outlets",
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]
                            },
                        ]
                    },
                ]
            },

        ]
    }
    useEffect(() => {
        // graphview()
    }, []);
    function getAbbreviation(key: string) {
        switch (key) {
            case 'Refineries':
                return 'RF-STG';
            case 'VLCC':
                return 'VLCC-PIPE';
            case 'Bulk Storage':
                return 'BULK-STG';
            case 'BulkTrans':
                return 'BULK-STG-TRANS';
            case 'Regional Distribution & Storge':
                return 'REG-DIST-STG';
            case 'Transportation':
                return 'REG-DIST-TRANS';
            case 'Outlets':
                return 'OUTLETS';
            default:
                return 'Unknown';
        }
    }
    const closeDialog = () => {
        setOpen(false)
    }
    const handleNodeClick = (params: any) => {
        console.log('Node clicked:', params.name);
        const abbreviation = getAbbreviation(params.name)
        setEvent(params.name)
        axios.get(Endpoints.Chart_Data, {
            params: {
                docType: "CERTIFICATE",
                productionID: state.tableData.ProductionID,
                certType: abbreviation
            }
        })
            .then((res) => {
                console.log(params.name)

                setDocumentData(res.data); setOpen(true); console.log(res.data.certificates)


                if (params.name === 'Refineries') {
                    setcurrentStepCount(1)
                } else if (params.name === 'VLCC' || params.name === 'Pipeline') {
                    setcurrentStepCount(2)
                } else if (params.name === 'Bulk Storage') {
                    setcurrentStepCount(3)
                } else if (params.name === 'Train' || params.name === 'Tanker') {
                    setcurrentStepCount(4)
                }else if (params.name === 'Regional Distribution & Storge') {
                    setcurrentStepCount(5)
                }else if (params.name === 'Transportation') {
                    setcurrentStepCount(6)
                }else if (params.name === 'Outlets') {
                    setcurrentStepCount(7)
                }
                if (res.data.certificates.certificateDetails.length == 0) {
                    swal("info", `There is No certificate on ${params.name}`, "info")
                }

            })
            .catch((err) => console.error(err))
    };
    const options = {
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove',
        },
        series: [
            {
                type: 'tree',

                data: [data],

                top: '1%',
                left: '7%',
                bottom: '1%',
                right: '20%',
                initialTreeDepth: 10,

                symbolSize: 7,

                label: {
                    position: 'left',
                    verticalAlign: 'middle',
                    align: 'right',
                    fontSize: 10
                },

                leaves: {
                    label: {
                        position: 'right',
                        verticalAlign: 'middle',
                        align: 'left'
                    }
                },

                emphasis: {
                    focus: 'descendant'
                },

                expandAndCollapse: true,
                animationDuration: 550,
                animationDurationUpdate: 750
            }
        ]
    };
    const events = {
        click: handleNodeClick,
    };
    return (
        <div>
            <Box>
                <DataTable
                    style={{ borderRadius: 3 }}
                    columns={columns}
                    data={rowData}
                    responsive={true}
                    customStyles={customStyles}
                    theme="default"
                    pagination
                    dense
                    striped
                    highlightOnHover={true}

                />
            </Box>
            {/* <div id="main" style={{ width: '100%', height: '400px' }} />
             */}
            <ReactECharts
                option={options}
                notMerge={true}
                lazyUpdate={true}
                style={{ height: '400px', width: '100%' }}
                onEvents={events}
            />

            {
                (open && documnetdata.certificates.certificateDetails.length > 0) ? (
                    <Certificate1
                        onOpen={open}
                        onClose={closeDialog}
                        status={"open"}
                        docData={documnetdata}
                        currentStepCount={currentStepCount}
                        nextStep={'bulk_storage'}
                        proceedHide={true}
                        key={2}
                        sx={{
                            "& .MuiDialog-root": {
                                borderRadius: "100px",
                                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                                zIndex: "9999 !important",
                            },
                        }}
                    />
                ) :
                    // <>{swal("info", `There is No certificate on ${event}`, "info")}</>
                    null
            }
        </div>
    )
}
