
export const Endpoints = {
  Create_Production_Info: 'https://realgreen.realware.tech/realGreen/createProdInfo',
  Create_Ref_Storage_Info: 'https://realgreen.realware.tech/realGreen/createRefStgInfo',
  Create_Vlcc_Pipe_Info: 'https://realgreen.realware.tech/realGreen/createVlccPipInfo',
  Create_Bulk_Storage_Info: 'https://realgreen.realware.tech/realGreen/createBulkStgInfo',
  Get_Emissions_Data: 'https://realgreen.realware.tech/realGreen/getEmmisionData/',
  Get_Country: 'https://realgreen.realware.tech/realGreen/dropDown/getCountry',
  Get_Machinery_Type: 'https://realgreen.realware.tech/realGreen/dropDown/getMachineryDropdown',
  Get_Fuel_Type: 'https://realgreen.realware.tech/realGreen/dropDown/getFuelDropdown',
  Get_Energy_Type: 'https://realgreen.realware.tech/realGreen/dropDown/getEnergyDropdown',
  Create_Bulk_TransPort: 'https://realgreen.realware.tech/realGreen/createBulkStrgTransInfo',
  Create_Reg_Dist_Storage:'https://realgreen.realware.tech/realGreen/createRegDistStgInfo',
  Create_Reg_Dist_Trans:'https://realgreen.realware.tech/realGreen/createRegDistStgTransInfo',
  Create_Outlet:'https://realgreen.realware.tech/realGreen/createOutletInfo',
  Trans:'http://4.188.237.94:8080/?tab=transactions&transId=',
  Chart_Data: 'https://realgreen.realware.tech/realGreen/getEndChartData/'

};
