import React from 'react'
import Vlcc_PipelineStep1 from './Vlcc_PipelineStep1'

export default function Vlcc_PipelineStep3(props:any) {
    console.log(props,"props")
  return (
    <div>
        <Vlcc_PipelineStep1 step={'option1'} steps={props.step}/>
    </div>
  )
}
