
import { Box, Breadcrumbs, Link, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
const steps = [
  'Refineries & Storage',
  'VLCC & Pipelines',
  'Bulk Storage',
  'Transportation',
  'Regional Distribution & Storage',
  'Transportation',
  'Outlets'
];

export default function Steppers() {
  const activestep = useSelector((state: any) => state.Stepper)
  return (
    <Box sx={{ width: '100%', my: 2 }}>
      <Stepper activeStep={activestep} alternativeLabel sx={{
        '& .MuiStepLabel-label, & .MuiStepLabel-alternativeLabel': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        },
        '& .MuiStepLabel-label': {
          marginBottom: '8px',
        },
        '& .MuiStepLabel-root .Mui-completed ': {
          color: 'green',
        },
      }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
