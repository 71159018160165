import React, { useState, useEffect } from 'react'
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography, styled } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Vlcc_PipelineStep1 from './Vlcc_PipelineStep1';
import Vlcc_PipelineStep3 from './Vlcc_PipelineStep3';
import { useNavigate } from 'react-router-dom';
import Certificate1 from '../components/Dialog/Certificate1';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setProduction } from '../Redux/Slices/ProductionCreationSlice';
import axios from 'axios';
import { Endpoints } from '../Enviornment/Endpoints';
import { toast } from 'react-toastify';
// import { setBulkData } from '../Redux/Slices/BulkStorageSlice';
import { setBulkData } from '../Redux/Slices/BulkStorageSlice';
export default function Vlcc_PipelineStep2(props: any) {
    const navigate = useNavigate();
    const [indexes, setIndex] = useState<any>(0)
    const [open, setOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = useState(props.step);
    const productionData = useSelector((state: any) => state.Production)
    const dispatch = useDispatch()
    const [documnetdata, setDocumentData] = useState<any>()
    const [rerender, setRerender] = useState(0);
    const [countries, setCountries] = useState<any>([])
    const [loader, setLoader] = useState(false);
    console.log(props.pipeline.pipeline, productionData, "props data")
    const handleChange = (event: SelectChangeEvent) => {
        setSelectedItem(event.target.value as string);
    };
    const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
        mode: "onChange",
        defaultValues: {
            vessel: [
                {
                    transferLiter: '',
                    origin: '',
                    destination: '',
                    vesselWeight: '',
                    vesselID: ''
                }
            ],

        },
    });
    const { fields: vesselInfoFields, append: appendVesselInfo, remove: removeVesselInfo } = useFieldArray({
        name: "vessel",
        control,
        rules: {
            required: "Enter All Details",
        },
    });
    const createData = (data: any) => {
        console.log(data, props.pipeline, "final")
        const newKeyValuePairs = {
            productionID: productionData.productionId,
            refineryID: productionData.identityId,
            refineryLocation: productionData.identityLocation,
            createdBy: "Admin",
            updatedBy: "",
            createdAt: moment().format('DD-MM-YYYY'),
            updatedAt: moment().format('DD-MM-YYYY'),
            pipeline: props.pipeline.pipeline.length > 0 ? props.pipeline.pipeline : []
        };
        const updatedData = {
            ...data,
            ...newKeyValuePairs,
        };
        console.log(updatedData)
        const sumOfTransferLiter = data.vessel.reduce((sum: any, item: any) => {
            return sum + parseInt(item.transferLiter, 10);
        }, 0);
        console.log(sumOfTransferLiter, "sum")
        if (sumOfTransferLiter === productionData.refinedOutput) {
            console.log("Sum of transferLiter values matches totRefOpAfterLeak");
            setLoader(true)
            axios.post(Endpoints.Create_Vlcc_Pipe_Info, updatedData).then((res: any) => {
                setLoader(false)
                if (res.data.status === "Success") {
                    toast.success(res.data.message)
                    dispatch(
                        setProduction({
                            productionId: productionData.productionId,
                            refinedOutput: res.data.data.vlccAndPipelineInfo.totRefOpAfterLeak,
                            identityId: productionData.identityId,
                            identityLocation: productionData.identityLocation
                        })
                    );
                    dispatch(
                        setBulkData({
                            bulkInfo: res.data.data.vlccAndPipelineInfo
                            // bulkInfo: res.data.data.vlccAndPipelineInfo
                        })
                    );
                    setDocumentData(res.data.data)
                    setOpen(true);

                }
            })


        } else {
            console.log("Sum of transferLiter values does not match totRefOpAfterLeak", typeof (sumOfTransferLiter), typeof (JSON.parse(productionData.refinedOutput)));
        }
    }
    const resetForm = () => {

    }
    const addVesselInfo = (index: any) => {
        setIndex(() => indexes + 1)
        appendVesselInfo({
            transferLiter: '',
            origin: '',
            destination: '',
            vesselWeight: '',
            vesselID: ''
        })
        setValue(`vessel.${index + 1}.origin`, productionData.identityLocation)
    }
    const removedVesselInfo = (index: any) => {
        setIndex(() => indexes - 1)
        removeVesselInfo(index)
    }
    const closeDialog = () => {
        setOpen(false)
    }
   
    const saveAsDraftForm = () => {
        let draftInfo = getValues()
        console.log(draftInfo)
    }
    const getCountries = () => {
        setLoader(true)
        axios.get(Endpoints.Get_Country).then((res: any) => {
            setLoader(false)
            console.log(res.data, "res")
            setCountries(res.data)
        })
    }
    const handleChangeCountry = (e: any) => {
    }
    useEffect(() => {
        setValue(`vessel.${0}.origin`, productionData.identityLocation)
        setRerender(rerender + 1)
        getCountries()
    }, [])
    return (
        <div>
            {selectedItem === 'option2' ?
                <Grid container spacing={2}>
                    <Grid item xs={4} className='grid_content'>
                        <img src={require('../asserts/Realgreen-Imgs/Screen4.png')} alt="Screen" className='screen_img' />
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs sx={{ pt: '0px !important' }}>
                        <Grid container spacing={2} >
                            <Grid item xs={3} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refinery Id:</Typography>&nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{productionData.identityId}</Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Production Id:</Typography>&nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{productionData.productionId}</Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refined Output:</Typography> &nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{parseFloat(productionData.refinedOutput).toLocaleString('en-IN')} Ltrs.</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                    <Select value={selectedItem} onChange={handleChange} labelId="demo-simple-select-label"
                                        id="demo-simple-select" label="Select">
                                        <MenuItem value="option1">Pipeline</MenuItem>
                                        <MenuItem value="option2">Vessel</MenuItem>
                                        <MenuItem value="option3">Both</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Box>
                            <form onSubmit={handleSubmit((data: any) => {
                                createData(data);
                            })}>
                                <Box sx={{ mb: 1, mt: 2 }}>
                                    <Typography className="grid_title">Vessel Info</Typography>
                                    {vesselInfoFields.map((field: any, index: any) => (
                                        <Paper sx={{ p: 2, my: 2 }} key={field.id}>
                                            <Grid container spacing={2} key={index} sx={{ my: 1 }}>
                                                <Grid item xs={10}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Vessel ID" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`vessel.${index}.vesselID`)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    id="outlined-basic" label="Transfer Litres" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`vessel.${index}.transferLiter`)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    key={field.id}
                                                                    id="outlined-basic" label="Origin" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    disabled
                                                                    {...register(`vessel.${index}.origin`)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Autocomplete
                                                                // key={rerender}
                                                                size="small"
                                                                options={countries}
                                                                getOptionLabel={(option: any) => option.countryName}
                                                                onChange={(event, newValue) => {
                                                                    handleChangeCountry(newValue)
                                                                }}

                                                                renderInput={(params) => <TextField {...params} {...register(`vessel.${index}.destination`)} label="Destination" />}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size='small'>
                                                                <TextField
                                                                    key={field.id}
                                                                    id="outlined-basic" label="Vessel Weight" variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    {...register(`vessel.${index}.vesselWeight`)}

                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start"> Tons.</InputAdornment>,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2} sx={{ margin: 'auto', textAlign: 'center' }}>
                                                    {/* {index == 0 ? <IconButton onClick={() => addVesselInfo(index)}><AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} /></IconButton> : null}
                                                    {index == 0 ? null : <IconButton onClick={() => removedVesselInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>} */}

                                                    {
                                                        index == indexes ?
                                                            <div>
                                                                {indexes < 4 ?
                                                                    <div>
                                                                        <IconButton onClick={() => addVesselInfo(index)}>
                                                                            <AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} />
                                                                        </IconButton>
                                                                        {index != 0 && <IconButton onClick={() => removedVesselInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}
                                                                    </div>
                                                                    : <IconButton onClick={() => removedVesselInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>
                                                                }
                                                            </div> :
                                                            <IconButton onClick={() => removedVesselInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} />
                                                            </IconButton>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                    ))}
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <LoadingButton
                                        loading={false}
                                        variant="contained"
                                        type="submit"
                                        className="submitbtn"
                                        size='small'
                                        sx={{

                                            fontWeight: 500,
                                            backgroundColor: "#555a64",
                                            color: "#fff",
                                            "& .MuiButtonBase-root:hover": {
                                                backgroundColor: "#555a64",
                                                color: "#fff",
                                            },
                                        }}
                                        onClick={(event) => resetForm()}
                                    >
                                        Reset
                                    </LoadingButton>

                                    <LoadingButton
                                        variant="contained"
                                        size="small"
                                        type="submit"
                                        loading={loader}
                                        color='success'
                                        sx={{ marginLeft: 2 }} // Add margin-left for space
                                    >
                                        Preview
                                    </LoadingButton>
                                    <LoadingButton
                                        variant="contained"
                                        size="small"
                                        type="submit"
                                        loading={false}
                                        color='primary'
                                        onClick={(event) => saveAsDraftForm()}
                                        sx={{ marginLeft: 2 }} // Add margin-left for space
                                    >
                                        Save As Draft
                                    </LoadingButton>
                                </Box>
                            </form>
                        </Box>

                    </Grid>
                </Grid> : selectedItem === 'option1' ? <Vlcc_PipelineStep1 step={selectedItem} /> : <Vlcc_PipelineStep3 step={selectedItem} />}
            <div className="custom-dialog">
                {open ? (
                    <Certificate1
                        onOpen={open}
                        onClose={closeDialog}
                        docData={documnetdata}
                        status={"open"}
                        currentStepCount={2}
                        nextStep={'bulk_storage'}
                        sx={{
                            "& .MuiDialog-root": {
                                borderRadius: "100px",
                                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                                zIndex: "9999 !important",
                            },
                        }}
                    />
                ) : null}
            </div>
        </div>
    )
}

