import { LoadingButton } from '@mui/lab';
import { Box, Divider, FormControl, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Certificate1 from '../components/Dialog/Certificate1';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { Endpoints } from '../Enviornment/Endpoints';
import { toast } from 'react-toastify';
import { setProduction } from '../Redux/Slices/ProductionCreationSlice';
import { setStepper } from '../Redux/Slices/StepperSlice';

export default function Bulk_Storage() {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setStepper(2))
  }, [])

  const [open, setOpen] = React.useState(false);
  const productionData = useSelector((state: any) => state.Production)
  const bulkStorageData = useSelector((state: any) => state.BulkStorage)
  const [documnetdata, setDocumentData] = useState<any>()
  const [loader, setLoader] = useState(false);
  const certificateData = useSelector((state: any) => state.Certificate)
  console.log(productionData, bulkStorageData)
  let combinedArray: any = [...bulkStorageData.bulkInfo.pipeline, ...bulkStorageData.bulkInfo.vessel];


  console.log(combinedArray, "combain")
  const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
    mode: "onChange",
    defaultValues: {
      origin: [
        {
          storageLiters: '',
          storageTime: '',
          electricity: '',
          avgVOCsEvaporations: '',
          avgPressure: '',
          avgLeakage: '',
          bulkStorageID: '',
          origin: ''
        }
      ],

    },
  });
  const { fields: storageInfoFields, append: appendStorageInfo, remove: removeStorageInfo } = useFieldArray({
    name: "origin",
    control,
    rules: {
      required: "Enter All Details",
    },
  });
  const createData = (data: any) => {
    combinedArray.filter((item: any, index: any) => {
      console.log(item, "item")
      setValue(`origin.${index}.origin`, item.destination)
    })
    const newKeyValuePairs = {
      productionID: productionData.productionId,
      refineryID: productionData.identityId,
      // refineryLocation: productionData.identityLocation,
      createdBy: "Admin",
      updatedBy: "",
      createdAt: moment().format('DD-MM-YYYY'),
      updatedAt: moment().format('DD-MM-YYYY'),
      vesselID: certificateData.vesselID

    };
    const updatedData = {
      ...data,
      ...newKeyValuePairs,
    };
    console.log(updatedData, "data")
    const sumOfTransferLiter = data.origin.reduce((sum: any, item: any) => {
      return sum + parseInt(item.storageLiters, 10);
    }, 0);
    if (sumOfTransferLiter === JSON.parse(productionData.refinedOutput)) {
      console.log("Sum of transferLiter values matches totRefOpAfterLeak");
      setLoader(true)
      axios.post(Endpoints.Create_Bulk_Storage_Info, updatedData).then((res: any) => {
        setLoader(false)
        if (res.data.status === "Success") {
          toast.success(res.data.message)
          dispatch(
            setProduction({
              productionId: res.data.data.bulkStorageInfo.productionID,
              refinedOutput: res.data.data.bulkStorageInfo.totRefOpAfterLeak,
              identityId: productionData.identityId,
              identityLocation: productionData.identityLocation
            })
          );
          setDocumentData(res.data.data)
          sessionStorage.setItem('countries', JSON.stringify(combinedArray))
          setOpen(true)
        }
      })
    }
    else {
      console.log("Sum of transferLiter values does not match totRefOpAfterLeak", sumOfTransferLiter, typeof (JSON.parse(productionData.refinedOutput)));
    }


  }
  const resetForm = () => { }
  const closeDialog = () => {
    setOpen(false)
  }
  const saveAsDraftForm = () => {
    let draftInfo = getValues()
    console.log(draftInfo)
  }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4} className='grid_content'>
          <img src={require('../asserts/Realgreen-Imgs/Screen5.png')} alt="Screen" className='screen_img' />

        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs sx={{ pt: '0px !important' }}>
          <Grid container spacing={2}>
            <Grid item xs={3} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refinery Id:</Typography>&nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{productionData.identityId}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Production Id:</Typography>&nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{productionData.productionId}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refined Output:</Typography> &nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{parseFloat(productionData.refinedOutput).toLocaleString('en-IN')}&nbsp;Ltrs.</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item>
              <form onSubmit={handleSubmit((data: any) => {
                createData(data);
              })}>
                {combinedArray.map((field: any, index: any) => (
                  <Box sx={{ mb: 1 }}>
                    <Typography className="grid_title">{field.destination}</Typography>
                    <Box>
                      <Grid container spacing={2} sx={{ my: 1 }}>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Bulk Storage ID" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.bulkStorageID`)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Storage Litres" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.storageLiters`)}
                              InputProps={{
                                inputProps: {
                                  max: combinedArray[0].afterLeakage,
                                },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Storage Time" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.storageTime`)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">Hrs.</InputAdornment>,
                              }}
                            />

                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Electricity" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.electricity`)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">KW.</InputAdornment>,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Average VOCs Evaporations" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.avgVOCsEvaporations`)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">mg/m3.</InputAdornment>,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Average Pressure" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.avgPressure`)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">Pa.</InputAdornment>,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth size='small'>
                            <TextField
                              id="outlined-basic" label="Average Leakage" variant="outlined"
                              size='small'
                              fullWidth
                              {...register(`origin.${index}.avgLeakage`)}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">Ltrs.</InputAdornment>,
                              }}
                            />
                          </FormControl>
                        </Grid>

                      </Grid>
                    </Box>
                  </Box>
                ))}

                <Box display="flex" justifyContent="center" alignItems="center">
                  <LoadingButton
                    loading={false}
                    variant="contained"
                    type="submit"
                    className="submitbtn"
                    size='small'
                    sx={{

                      fontWeight: 500,
                      backgroundColor: "#555a64",
                      color: "#fff",
                      "& .MuiButtonBase-root:hover": {
                        backgroundColor: "#555a64",
                        color: "#fff",
                      },
                    }}
                    onClick={resetForm}
                  >
                    Reset
                  </LoadingButton>

                  <LoadingButton
                    variant="contained"
                    size="small"
                    type="submit"
                    loading={loader}
                    color='success'
                    sx={{ marginLeft: 2 }} // Add margin-left for space
                  >
                    Preview
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    type="submit"
                    loading={false}
                    color='primary'
                    onClick={(event) => saveAsDraftForm()}
                    sx={{ marginLeft: 2 }} // Add margin-left for space
                  >
                    Save As Draft
                  </LoadingButton>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="custom-dialog">
        {open ? (
          <Certificate1
            onOpen={open}
            docData={documnetdata}
            onClose={closeDialog}
            status={"open"}
            key={3}
            currentStepCount={3}
            nextStep={'Transportation'}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </div>
  )
}
