import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Typography, Paper, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import pieChartCertificate from '../Echarts/pieChartCertificate';
import ReactECharts from 'echarts-for-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStepper } from '../../Redux/Slices/StepperSlice';
import { setProduction } from '../../Redux/Slices/ProductionCreationSlice';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { usePDF } from 'react-to-pdf';
import { setCertificateData } from '../../Redux/Slices/CertificateSlice';
import { Endpoints } from '../../Enviornment/Endpoints';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const customStyles = {
    rows: {
        style: {
            fontSize: '14px',

        },
    },
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
    },
};
export default function Certificate1(props: any) {
    console.log(props.docData, typeof(props.currentStepCount))

    console.log(props)
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const { toPDF, targetRef } = usePDF({
        filename: ' CARBON FOOTPRINT CERTIFICATE 2023 .pdf'
    });
    const columns: TableColumn<any>[] = [
        {
            name: 'Scope of GHG Emissions',
            selector: row => row.scope.toLocaleString('en-IN'),
            sortable: true,
            width: '300px'
        },
        {
            name: 'CO2e(MT)',
            selector: row => row.co2.toLocaleString('en-IN'),
            sortable: true,
        },
        {
            name: '%',
            selector: row => row.percentage.toLocaleString('en-IN'),
            sortable: true,
        },
    ];
    const totalRow = {
        scope: 'Total',
        co2: props.docData.certificates.certificateDetails[0].total.toLocaleString(), // you can calculate the total if needed
        percentage: parseFloat(props.docData.certificates.certificateDetails[0].directEmmPer) + parseFloat(props.docData.certificates.certificateDetails[0].indirectEmmPer) + parseFloat(props.docData.certificates.certificateDetails[0].otherIndirectEmmPer) // you can calculate the total percentage if needed
    };
    const [rowData, setRowData] = useState<any>([
        {
            scope: 'Direct emissions to air',
            co2: props.docData.certificates.certificateDetails[0].directEmm.toLocaleString('en-IN'),
            percentage: props.docData.certificates.certificateDetails[0].directEmmPer.toLocaleString('en-IN')
        },
        {
            scope: 'Indirect emissions from purchased energy',
            co2: props.docData.certificates.certificateDetails[0].indirectEmm.toLocaleString('en-IN'),
            percentage: props.docData.certificates.certificateDetails[0].indirectEmmPer.toLocaleString('en-IN')
        },
        {
            scope: 'Other Indirect emissions',
            co2: props.docData.certificates.certificateDetails[0].otherIndirectEmm.toLocaleString('en-IN'),
            percentage: props.docData.certificates.certificateDetails[0].otherIndirectEmmPer.toLocaleString('en-IN')
        },
    ])
    let option = {
        toolbox: {
            show: true,

        },
        series: [
            {
                name: 'Nightingale Chart',
                type: 'pie',
                radius: [50, 80],
                center: ['50%', '50%'],

                data: [
                    { value: props.docData.certificates.certificateDetails[0].directEmmPer.toLocaleString('en-IN'), name: 'Direct' },
                    { value: props.docData.certificates.certificateDetails[0].indirectEmmPer.toLocaleString('en-IN'), name: 'Indirect' },
                    { value: props.docData.certificates.certificateDetails[0].otherIndirectEmmPer.toLocaleString('en-IN'), name: 'Other' }
                ]
                

            }
        ]
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleProceed = () => {
        dispatch(setStepper(1))
        setOpen(props.onClose)
        dispatch(
            setProduction(props.data)
        );
        dispatch(
            setCertificateData(props.docData.certificates.certificateDetails[0])
        )
            
            navigate(`/${props.nextStep}`)

    }

    return (
        <Dialog
            open={props.onOpen}
            onClose={props.onClose}
            sx={{
                "& .MuiDialog-root": {},
                zIndex: 10000,
                "& .MuiDialog-container": {
                    right: 0,
                    position: 'absolute',
                    width: '75%',
                    justifyContent: 'end'
                },
                "& .MuiDialog-paper": {
                    width: '90%',
                    maxWidth: '1000px',
                    maxHeight: '1000px',
                    justifyContent: 'end',
                    margin: '0px'
                }

            }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            </DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                    <IconButton onClick={() => toPDF()} title='Download' sx={{ fontSize: '16px', letterSpacing: '0.6px', color: 'black' }}>DOWNLOAD <ArrowDownwardOutlinedIcon /></IconButton>
                </Box>
                <Box>
                    <IconButton
                        aria-label="close"
                        onClick={props.onClose}
                        sx={{

                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent dividers className='certificates' ref={targetRef}>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography> CARBON FOOTPRINT</Typography>
                        <Typography variant='h5' className='certificate_title2'>CERTIFICATE <strong style={{ color: '#8cc92e' }}>2023</strong></Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <img src={require('../../asserts/logo.png')} alt="logo" className='app_logo' style={{ float: 'right' }} />
                    </Grid>
                </Grid>
                <Divider sx={{ borderWidth: '1px', borderColor: '#115C23 !important' }} />
                <Typography>Owner Ship Transfer</Typography>
                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    
                    {props.currentStepCount == 1 ?
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Refinery ID :&nbsp;  </Typography>
                            <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].refineryID}</Typography>

                        </Box> : props.currentStepCount == 2 ? <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Refinery ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].refineryID}</Typography></Box>&nbsp;&nbsp;&nbsp;<ArrowForwardIcon/>&nbsp;&nbsp;&nbsp;
                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Vessel ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].vesselID}</Typography></Box>

                        </Box> : props.currentStepCount == 3 ? <Box sx={{ display: 'flex', width: '100%' }}>

                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Vessel ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].vesselID}</Typography>&nbsp;&nbsp;&nbsp;</Box><ArrowForwardIcon/>&nbsp;&nbsp;&nbsp;
                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Bulk Storage ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].bulkStorageID}</Typography></Box>

                        </Box> : props.currentStepCount == 4 ? <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Bulk Storage ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].bulkStorageID}</Typography></Box>&nbsp;&nbsp;&nbsp;<ArrowForwardIcon/>&nbsp;&nbsp;&nbsp;
                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Transporter ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].bulkStgTransID}</Typography></Box>

                        </Box> : props.currentStepCount == 5 ? <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Transporter ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].bulkStgTransID}</Typography></Box>&nbsp;&nbsp;&nbsp;<ArrowForwardIcon/>&nbsp;&nbsp;&nbsp;
                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Regional Distribution Storage ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].regDistStgID}</Typography></Box>


                        </Box> : props.currentStepCount == 6 ? <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Regional Distribution Storage ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].regDistStgID}</Typography></Box>&nbsp;&nbsp;&nbsp;<ArrowForwardIcon/>&nbsp;&nbsp;&nbsp;
                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Transporter ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].regDistStgTransID}</Typography></Box>&nbsp;&nbsp;&nbsp;



                        </Box> : <Box sx={{ display: 'flex', width: '100%' }}>

                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Transporter ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].regDistStgTransID}</Typography></Box>&nbsp;&nbsp;&nbsp;<ArrowForwardIcon/>&nbsp;&nbsp;&nbsp;
                            <Box sx={{ display: 'flex' }}><Typography sx={{ fontSize: '14px', fontWeight: 'bold !important' }}>Outlet ID :&nbsp;  </Typography>
                                <Typography sx={{ fontSize: '14px' }}>{props.docData.certificates.certificateDetails[0].outletID}</Typography></Box>
                        </Box>

                    }

                </Box>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={8}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 'bold !important', mb: 2 }}>CO2e SUMMARY</Typography>
                        <DataTable
                            columns={columns}
                            data={[...rowData, totalRow]}
                            responsive={true}
                            theme="default"

                            striped
                            noHeader
                            pagination={false}
                            customStyles={customStyles}
                            noDataComponent={<div>No data available</div>}
                        />
                        <Typography sx={{ fontSize: '12px', fontWeight: 'bold', mt: 1 }} >Emissions are compared to the base year 2023</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <ReactECharts option={option} />
                    </Grid>

                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 'bold', mt: 1 }} >Transaction ID: <a style={{ color: 'blue' }} href={Endpoints.Trans + props.docData.certificates.certificateDetails[0].txnId} target='_blank'>{props.docData.certificates.certificateDetails[0].txnId}</a> </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id="standard-basic"
                            variant="standard"
                        />
                        <Typography sx={{ fontSize: '12px', fontWeight: 'bold', mt: 1 }} >Date</Typography>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                {props.proceedHide === undefined  ?
                <Button autoFocus
                    onClick={handleProceed} variant="contained" color='success' sx={{ alignItems: 'center', mb: 3 }}>
                    Proceed
                </Button>:''}
            </DialogActions>
        </Dialog >
    )
}
