import { createSlice } from "@reduxjs/toolkit";
const state: any = []
const CertificateSlice = createSlice({
    name: 'BulkStorageSlice',
    initialState: state,
    reducers: {
        setCertificateData(state, action) {
            console.log("Payload is ...", action)
            // state.push(action.payload)
            return {
                ...state,
                ...action.payload,
            };
        }
    }
})

export const { setCertificateData } = CertificateSlice.actions;
export default CertificateSlice.reducer;