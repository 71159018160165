import { AppBar, Badge, Box, Button, IconButton, Toolbar } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
export default function Header() {
    return (
        <AppBar className='topBar' sx={{ position: 'static' }}>
            <Toolbar className='toolbar'>
                <img src={require('../../asserts/Realgreen-Imgs/RealGreen-Logo1.png')} alt="logo" className='app_logo' />&nbsp;&nbsp;
                <NavLink to="/" className="nav-link" end>
                    {({ isActive }) => (
                        <Button
                            style={{ color: isActive ? '#fff' : '#ff',borderRadius:'0px' }}
                            className={isActive ? 'active-link' : ''}
                        >
                            Calculate Emission
                        </Button>
                    )}
                </NavLink>

                <NavLink to="/emissions" className="nav-link">
                    {({ isActive }) => (
                        <Button
                            style={{ color: isActive ? '#fff' : '#ff', borderRadius:'0px' }}
                            className={isActive ? 'active-link' : ''}
                        >
                            Emission Report & Certificates
                        </Button>
                    )}
                </NavLink>
            </Toolbar>

        </AppBar>
    )
}
