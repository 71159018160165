import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography, styled, FormControl, FormControlLabel, } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import Vlcc_PipelineStep2 from './Vlcc_PipelineStep2';
import Vlcc_PipelineStep3 from './Vlcc_PipelineStep3';
import Certificate1 from '../components/Dialog/Certificate1';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { Endpoints } from '../Enviornment/Endpoints';
import { toast } from 'react-toastify';
import { setProduction } from '../Redux/Slices/ProductionCreationSlice';
import { setBulkData } from '../Redux/Slices/BulkStorageSlice';
import { setStepper } from '../Redux/Slices/StepperSlice';
export default function Vlcc_PipelineStep1(props: any) {
    const dispatch = useDispatch()
    const [countries, setCountries] = useState<any>([])
    const [indexes, setIndex] = useState<any>(0)
    const navigate = useNavigate();
    const [documnetdata, setDocumentData] = useState<any>()
    const [rerender, setRerender] = useState(0)
    const [open, setOpen] = React.useState(false);
    const [showVlccPipelineStep2, setShowVlccPipelineStep2] = useState<any>(false);
    const [selectedItem, setSelectedItem] = useState(props.step);
    const productionData = useSelector((state: any) => state.Production)
    const [pipelineData, setPipelineData] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const handleChange = (event: SelectChangeEvent) => {
        setSelectedItem(event.target.value as string);
    };
    const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
        mode: "onChange",
        defaultValues: {
            pipeline: [
                {
                    transferLiter: '',
                    origin: '',
                    destination: '',
                    pipeDistance: '',
                    avgFlowRate: '',
                    avgTemperature: '',
                    avgLeakage: ''
                }
            ],
        },
    });
    const { fields: pipelineInfoFields, append: appendPipelineInfo, remove: removePipelineInfo } = useFieldArray({
        name: "pipeline",
        control,
        rules: {
            required: "Enter All Details",
        },
    });
    const createData = (data: any) => {

        const newKeyValuePairs = {
            productionID: productionData.productionId,
            refineryID: productionData.identityId,
            refineryLocation: productionData.identityLocation,
            createdBy: "Admin",
            updatedBy: "",
            createdAt: moment().format('DD-MM-YYYY'),
            updatedAt: moment().format('DD-MM-YYYY')

        };
        const updatedData = {
            vessel: [],
            ...data,
            ...newKeyValuePairs,
        };
        console.log(updatedData)
        const sumOfTransferLiter = data.pipeline.reduce((sum: any, item: any) => {
            return sum + parseInt(item.transferLiter, 10);
        }, 0);
        console.log(sumOfTransferLiter, "sum")
        if (sumOfTransferLiter === JSON.parse(productionData.refinedOutput)) {
            console.log("Sum of transferLiter values matches totRefOpAfterLeak");
            setLoader(true)
            axios.post(Endpoints.Create_Vlcc_Pipe_Info, updatedData).then((res: any) => {
                setLoader(false)
                if (res.data.status === "Success") {
                    toast.success(res.data.message)
                    dispatch(
                        setBulkData({
                            bulkInfo: res.data.data.vlccAndPipelineInfo
                        })
                    );
                    setDocumentData(res.data.data)
                    setOpen(true);
                }
            })
        } else {
            console.log("Sum of transferLiter values does not match totRefOpAfterLeak", typeof (sumOfTransferLiter), typeof (JSON.parse(productionData.refinedOutput)));
        }

    }
    const resetForm = () => {

    }
    const addPipelineInfo = (index: any) => {
        setIndex(() => indexes + 1)
        appendPipelineInfo({
            transferLiter: '',
            origin: '',
            destination: '',
            pipeDistance: '',
            avgFlowRate: '',
            avgTemperature: '',
            avgLeakage: ''
        })
        setValue(`pipeline.${index + 1}.origin`, productionData.identityLocation)
    }
    const removedPipelineInfo = (index: any) => {
        setIndex(() => indexes - 1)
        removePipelineInfo(index)
    }
    const nextForm = () => {
        let pipelineData = getValues()
        setPipelineData(pipelineData)
        const sumOfTransferLiter = pipelineData.pipeline.reduce((sum: any, item: any) => {
            return sum + parseInt(item.transferLiter, 10);
        }, 0);
        if (JSON.parse(productionData.refinedOutput) < sumOfTransferLiter) {
            alert("error")
        } else {
            setShowVlccPipelineStep2(true);
            setSelectedItem('option2')
            dispatch(
                setProduction({
                    productionId: productionData.productionId,
                    refinedOutput: JSON.parse(productionData.refinedOutput) - sumOfTransferLiter
                })
            );
        }

    }
    const closeDialog = () => {
        setOpen(false)
    }
    const saveAsDraftForm = () => {
        let draftInfo = getValues()
        console.log(draftInfo)
    }
    const getCountries = () => {
        setLoader(true)
        axios.get(Endpoints.Get_Country).then((res: any) => {
            setLoader(false)
            console.log(res.data, "res")
            setCountries(res.data)
        })
    }
    const handleChangeCountry = (e: any) => { }
    useEffect(() => {
        setValue(`pipeline.${0}.origin`, productionData.identityLocation)
        dispatch(setStepper(1))
        setRerender(rerender + 1)
        getCountries()
    }, [])
    return (
        <div>
            {selectedItem === 'option1' && !showVlccPipelineStep2 ?
                <Grid container spacing={2}>
                    <Grid item xs={4} className='grid_content'>
                        <img src={require('../asserts/Realgreen-Imgs/Screen3.png')} alt="Screen" className='screen_img' />
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs sx={{ pt: '0px !important' }}>
                        <Grid container spacing={2} >
                            <Grid item xs={3} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refinery Id:</Typography>&nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{productionData.identityId}</Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Production Id:</Typography>&nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{productionData.productionId}</Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex' }}>
                                <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refined Output:</Typography> &nbsp;
                                <Typography sx={{ margin: 'auto 0' }}>{parseFloat(productionData.refinedOutput).toLocaleString('en-IN')} Ltrs.</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                    <Select value={props.steps === undefined ? selectedItem : 'option3'} onChange={handleChange} labelId="demo-simple-select-label"
                                        id="demo-simple-select" label="Select">
                                        <MenuItem value="option1">Pipeline</MenuItem>
                                        <MenuItem value="option2">Vessel</MenuItem>
                                        <MenuItem value="option3">Both</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Box>
                            <form onSubmit={handleSubmit((data: any) => {
                                createData(data);
                            })}>
                                <Box sx={{ mb: 1, mt: 2 }}>
                                    <Typography className="grid_title">Pipeline Info</Typography>
                                    {pipelineInfoFields.map((field: any, index: any) => (

                                        <Paper sx={{ p: 2, my: 2 }} key={field.id}>
                                            {index < 5 &&
                                                <Grid container spacing={2} sx={{ my: 1 }}>
                                                    <Grid item xs={10}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={4}>
                                                                <FormControl fullWidth size='small'>
                                                                    <TextField
                                                                        key={field.id}
                                                                        id="outlined-basic" label="Transfer Litres" variant="outlined"
                                                                        size='small'
                                                                        fullWidth
                                                                        {...register(`pipeline.${index}.transferLiter`)}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <FormControl fullWidth size='small'>
                                                                    <TextField
                                                                        id="outlined-basic" label="Origin" variant="outlined"
                                                                        size='small'
                                                                        fullWidth
                                                                        disabled
                                                                        sx={{ color: "#000" }}
                                                                        {...register(`pipeline.${index}.origin`)}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Autocomplete

                                                                    size="small"

                                                                    options={countries}
                                                                    getOptionLabel={(option: any) => option.countryName}
                                                                    onChange={(event, newValue) => {
                                                                        handleChangeCountry(newValue)
                                                                    }}

                                                                    renderInput={(params) => <TextField {...params} {...register(`pipeline.${index}.destination`)} label="Destination" />}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <FormControl fullWidth size='small'>
                                                                    <TextField
                                                                        key={field.id}
                                                                        id="outlined-basic" label="Pipeline Distance" variant="outlined"
                                                                        size='small'
                                                                        fullWidth
                                                                        {...register(`pipeline.${index}.pipeDistance`)}

                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="start"> kms.</InputAdornment>,
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <FormControl fullWidth size='small'>
                                                                    <TextField
                                                                        key={field.id}
                                                                        id="outlined-basic" label="Average Flow Rate" variant="outlined"
                                                                        size='small'
                                                                        fullWidth
                                                                        {...register(`pipeline.${index}.avgFlowRate`)}

                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="start"> GPM.</InputAdornment>,
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <FormControl fullWidth size='small'>
                                                                    <TextField
                                                                        key={field.id}
                                                                        id="outlined-basic" label="Average Temperature" variant="outlined"
                                                                        size='small'
                                                                        fullWidth
                                                                        {...register(`pipeline.${index}.avgTemperature`)}

                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="start"> .C</InputAdornment>,
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <FormControl fullWidth size='small'>
                                                                    <TextField
                                                                        key={field.id}
                                                                        id="outlined-basic" label="Average Leakage" variant="outlined"
                                                                        size='small'
                                                                        fullWidth
                                                                        {...register(`pipeline.${index}.avgLeakage`)}

                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="start"> Ltrs.</InputAdornment>,
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{ margin: 'auto', textAlign: 'center' }}>
                                                        {/* {index == 0 ? <IconButton onClick={() => addPipelineInfo(index)}><AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} /></IconButton> : null}
                                                        {index == 0 ? null : <IconButton onClick={() => removedPipelineInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>} */}

                                                        {
                                                            index == indexes ?
                                                                <div>
                                                                    {indexes < 4 ?
                                                                        <div>
                                                                            <IconButton onClick={() => addPipelineInfo(index)}>
                                                                                <AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} />
                                                                            </IconButton>
                                                                            {index != 0 && <IconButton onClick={() => removedPipelineInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}
                                                                        </div>
                                                                        : <IconButton onClick={() => removedPipelineInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>
                                                                    }
                                                                </div> :
                                                                <IconButton onClick={() => removedPipelineInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} />
                                                                </IconButton>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Paper>

                                    ))}
                                </Box>
                                {props.steps === undefined ?
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <LoadingButton
                                            loading={false}
                                            variant="contained"
                                            type="submit"
                                            className="submitbtn"
                                            size='small'
                                            sx={{

                                                fontWeight: 500,
                                                backgroundColor: "#555a64",
                                                color: "#fff",
                                                "& .MuiButtonBase-root:hover": {
                                                    backgroundColor: "#555a64",
                                                    color: "#fff",
                                                },
                                            }}
                                            onClick={(event) => resetForm()}
                                        >
                                            Reset
                                        </LoadingButton>

                                        <LoadingButton
                                            variant="contained"
                                            size="small"
                                            type="submit"
                                            loading={loader}
                                            color='success'
                                            sx={{ marginLeft: 2 }} // Add margin-left for space
                                        >
                                            Preview
                                        </LoadingButton>
                                        <LoadingButton
                                            variant="contained"
                                            size="small"
                                            type="submit"
                                            loading={false}
                                            color='primary'
                                            onClick={(event) => saveAsDraftForm()}
                                            sx={{ marginLeft: 2, fontFamily: 'Roboto', }} // Add margin-left for space
                                        >
                                            Save as draft
                                        </LoadingButton>
                                    </Box> : <Box display="flex" justifyContent="end" alignItems="center">
                                        <LoadingButton
                                            loading={false}
                                            variant="contained"
                                            type="submit"
                                            className="submitbtn"
                                            size='small'
                                            color='primary'
                                            sx={{

                                                fontWeight: 500,
                                                // backgroundColor: "#555a64",
                                                // color: "#fff",
                                                float: 'right',
                                                "& .MuiButtonBase-root:hover": {
                                                    backgroundColor: "#555a64",
                                                    color: "#fff",
                                                },
                                            }}
                                            onClick={nextForm}
                                        >
                                            Next
                                        </LoadingButton>
                                    </Box>}
                            </form>
                        </Box>

                    </Grid>
                </Grid> : selectedItem === 'option2' ? <Vlcc_PipelineStep2 step={selectedItem} pipeline={pipelineData} /> : <Vlcc_PipelineStep3 step={selectedItem} />}
            <div className="custom-dialog">
                {open ? (
                    <Certificate1
                        onOpen={open}
                        onClose={closeDialog}
                        status={"open"}
                        docData={documnetdata}
                        currentStepCount={2}
                        nextStep={'bulk_storage'}
                        key={2}
                        sx={{
                            "& .MuiDialog-root": {
                                borderRadius: "100px",
                                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                                zIndex: "9999 !important",
                            },
                        }}
                    />
                ) : null}
            </div>

        </div>
    )
}
