import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import Certificate1 from '../components/Dialog/Certificate1';
import Vlcc_PipelineStep1 from './Vlcc_PipelineStep1';
import Vlcc_PipelineStep2 from './Vlcc_PipelineStep2';
import Vlcc_PipelineStep3 from './Vlcc_PipelineStep3';
import { useSelector } from 'react-redux';

export default function Vlcc_Pipelines() {
  const [selectedItem, setSelectedItem] = useState('option1');
  const stepp = useSelector((state:any)=>state.Stepper)
  useEffect(()=>{
      console.log("Stepper size is..",stepp)
  },[])
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedItem(event.target.value as string);
  };

  return (
    <div>
      <Vlcc_PipelineStep1 step={'option1'} />
    </div>
  )
}
