
import { useState, useEffect } from 'react'
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography, styled } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DataTable, { TableColumn } from "react-data-table-component";
import { Endpoints } from '../Enviornment/Endpoints';
import Emission_Tree from './Emission_Tree';
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7'
        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },

};
export default function Emission_Report() {
    const navigate = useNavigate()
    const [rowData, setRowData] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([])
    const [selectedItem, setSelectedItem] = useState('FY 23-24');
    const [isShowEmissions, setIsShowEmissions] = useState(false)
    const [productionId, setProductionId] = useState<any>()
    const [loader, setLoader] = useState(false);
    const [dashboardCounts, setDashboardCounts] = useState<any>()
    const columns: TableColumn<any>[] = [
        {
            name: "PRODUCTION ID",
            selector: row => row.ProductionID !== '' ? row.ProductionID : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px'
        },
        {
            name: "SCOPE1 CO2e IN MT ",
            selector: row => row.Scope1 !== '' && row.Scope1 !== null && row.Scope1 != undefined ? parseFloat(row.Scope1).toLocaleString('en-IN') : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        },
        {
            name: "SCOPE2 CO2e IN MT ",
            selector: row => row.Scope2 !== '' && row.Scope2 !== null && row.Scope2 != undefined ? row.Scope2.toLocaleString('en-IN') : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        },
        {
            name: "SCOPE3 CO2e IN MT ",
            selector: row => row.Scope3 !== '' && row.Scope3 !== null && row.Scope3 != undefined ? row.Scope3.toLocaleString('en-IN') : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        },
        {
            name: "TOTAL CO2e IN MT ",
            selector: row => row.Total !== '' && row.Total !== null && row.Total != undefined ? row.Total.toLocaleString('en-IN') : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        },
        {
            name: 'EMISSION DETAILS',
            cell: (row: any) => <Button size="small" variant="contained" sx={{ backgroundColor: '#5fb25c' }} onClick={() => handleView(row)}> View</Button>,
            width: '200px'
        }
    ]
    const handleChange = (event: SelectChangeEvent) => {
        setSelectedItem(event.target.value as string);
    };
    const handleView = (data: any) => {
        console.log(data, "datta")
        // setIsShowEmissions(false)
        setTableData(data)
        setProductionId(data.ProductionID)
        navigate("/emissions_Info", {
            state: {
                productionId: data.ProductionID,
                tableData: data
            }
        })
    }
    useEffect(() => {
        getEmissions()
    }, []);
    const getEmissions = () => {
        let payload = {
            docType: 'CERTIFICATE',
            startDate: '01-04-2023',
            endDate: '31-03-2024'
        }
        setLoader(true)
        axios.get(Endpoints.Get_Emissions_Data, {
            params: payload
        }).then((res: any) => {
            setLoader(false)
            setRowData(res.data.data)
            setDashboardCounts(res.data.totals)
            setIsShowEmissions(true)
        })
    }
    return (

        <div>
            {isShowEmissions ?
                <Box>
                    <Box sx={{ width: '20%' }}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Select onChange={handleChange} labelId="demo-simple-select-label" value={selectedItem}
                                id="demo-simple-select" label="Select">
                                <MenuItem value="FY 23-24">FY 23-24</MenuItem>
                                <MenuItem value="FY 22-23">FY 22-23</MenuItem>
                                <MenuItem value="FY 21-22">FY 21-22</MenuItem>
                                <MenuItem value="FY 20-21">FY 20-21</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
                            <Paper elevation={0} className="card">
                                <Box className="card-body">
                                    <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                                        <Grid item xs={9}>
                                            <Typography className="dashboard-title">Scope1</Typography>
                                            <Typography
                                                variant="h3"
                                                className="dashboard_info"
                                                sx={{ my: 1, fontSize: 20, fontWeight: 'bold' }}
                                            >
                                                {parseFloat(dashboardCounts.Scope1Total).toLocaleString('en-IN')}&nbsp;MT of CO2e
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ alignSelf: "center", position: 'relative' }}>
                                            <small className="custom-intransit-info"></small>
                                            <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                                                <img
                                                    src={require("../asserts/Icons/factory.png")}
                                                    className="ti ti-users font-24 align-self-center text-muted"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
                            <Paper elevation={0} className="card">
                                <Box className="card-body">
                                    <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                                        <Grid item xs={9}>
                                            <Typography className="dashboard-title">Scope2</Typography>
                                            <Typography
                                                variant="h3"
                                                className="dashboard_info"
                                                sx={{ my: 1, fontSize: 20, fontWeight: 'bold' }}
                                            >
                                                {parseFloat(dashboardCounts.Scope2Total).toLocaleString('en-IN')}&nbsp; MT of CO2e
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={3} sx={{ alignSelf: "center", position: 'relative' }}>
                                            <small className="custom-intransit-info"></small>
                                            <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                                                <img
                                                    src={require("../asserts/Icons/electric-factory.png")}
                                                    className="ti ti-users font-24 align-self-center text-muted"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
                            <Paper elevation={0} className="card">
                                <Box className="card-body">
                                    <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                                        <Grid item xs={9}>
                                            <Typography className="dashboard-title">Scope3</Typography>
                                            <Typography
                                                variant="h3"
                                                className="dashboard_info"
                                                sx={{ my: 1, fontSize: 20, fontWeight: 'bold' }}
                                            >
                                                {parseFloat(dashboardCounts.Scope3Total).toLocaleString('en-IN')}&nbsp; MT of CO2e
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ alignSelf: "center", position: 'relative' }}>
                                            <small className="custom-intransit-info"></small>
                                            <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                                                <img
                                                    src={require("../asserts/Icons/line-icon-for-emission-vector.png")}
                                                    className="ti ti-users font-24 align-self-center text-muted"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
                            <Paper elevation={0} className="card">
                                <Box className="card-body">
                                    <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                                        <Grid item xs={9}>
                                            <Typography className="dashboard-title">Total Emission</Typography>
                                            <Typography
                                                variant="h3"
                                                className="dashboard_info"
                                                sx={{ my: 1, fontSize: 20, fontWeight: 'bold' }}
                                            >
                                                {parseFloat(dashboardCounts.GrandTotal).toLocaleString('en-IN')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ alignSelf: "center", position: 'relative' }}>
                                            <small className="custom-intransit-info"></small>
                                            <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                                                <img
                                                    src={require("../asserts/Icons/co2-emission.png")}
                                                    className="ti ti-users font-24 align-self-center text-muted"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box sx={{ p: 0, mt: 1 }}>
                        <DataTable
                            style={{ borderRadius: 3 }}
                            columns={columns}
                            data={rowData}
                            responsive={true}
                            customStyles={customStyles}
                            theme="default"
                            pagination
                            dense
                            striped
                            highlightOnHover={true}

                        />
                    </Box>
                </Box> : <Box>
                    {/* <Emission_Tree productionId={productionId} tableData={tableData} /> */}
                    {/* <></> */}
                </Box>
            }
        </div>
    )
}
