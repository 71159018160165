import { createSlice } from "@reduxjs/toolkit";
const state: any = 0;
const StepperSlice = createSlice({
    name: 'SteeperSlice',
    initialState: state,
    reducers: {
        setStepper(state, action) {
            console.log(action.payload)
            state = action.payload;
            return state;
        }
    }
})

export const { setStepper } = StepperSlice.actions;
export default StepperSlice.reducer;