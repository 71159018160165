import { createSlice } from "@reduxjs/toolkit";

const Auth = createSlice({
    name:'Authentication',
    initialState:{
        isauth:false
    },
    reducers:{
        setAuth(state,action){
            state.isauth=action.payload
        }
    }
})

export const {setAuth} = Auth.actions;
export default Auth.reducer;