import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import Login from "../components/Layout/Login";
import Layout from "../components/Layout/Layout";
import Refineries_Storages from "../pages/Refineries_Storages";
import Vlcc_Pipelines from "../pages/Vlcc_Pipelines";
import Bulk_Storage from "../pages/Bulk_Storage";
import Transportation from "../pages/Transportation";
import Regional_Distribution_Storage from "../pages/Regional_Distribution_Storage";
import ProductionCreation from "../pages/ProductionCreation";
import Transportations_Two from "../pages/Transportations_Two";
import Outlets from "../pages/Outlets";
import Emission_Report from "../pages/Emission_Report";
import Emission_Tree from "../pages/Emission_Tree";
import { ErrorBoundary } from 'react-error-boundary'
const RootRouter = createHashRouter([
  {
    path: '/login',
    element: <Login />
  },
  {
    path: "/",
    element: <Layout />,

    children: [
      {
        path: "/",
        element:
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <ProductionCreation />
          </ErrorBoundary>,

      },
      {
        path: "refineries_Storages",
        element: <Refineries_Storages />,

      },
      {
        path: "vlcc_pipelines",
        element: <Vlcc_Pipelines />,

      },
      {
        path: "bulk_storage",
        element: <ErrorBoundary fallback={<div>Something went wrong</div>}><Bulk_Storage /></ErrorBoundary>,

      },
      {
        path: "Transportation",
        element: <Transportation />,
      },
      {
        path: "Regional",
        element: <Regional_Distribution_Storage />,

      },
      {
        path: "Transportation1",
        element: <Transportations_Two />,

      },
      {
        path: "outlets",
        element: <Outlets />,

      },
      {
        path: 'emissions',
        element: <Emission_Report />
      },
      {
        path: 'emissions_Info',
        element: <Emission_Tree />
      },


    ],
  },
]);

export default RootRouter;