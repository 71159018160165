import { LoadingButton } from '@mui/lab';
import { Box, FormControl, Grid, InputAdornment, InputLabel, TextField, Select, MenuItem, Divider } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '../Enviornment/Endpoints';
import moment from 'moment';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setProduction } from '../Redux/Slices/ProductionCreationSlice';
import { productionCreate } from '../services/productionCreation';
import { useState, useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import swal from 'sweetalert';
import { Global } from '../GlobalStyles';
// import { useMutation } from 'react-query';
export default function ProductionCreation() {
    const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
        mode: "onChange",
        defaultValues: {
            productionID: '',
            startDate: '',
            endDate: '',
            crudeInput: '',
            refinedOutput: '',
            refineryID: '',
            refineryLocation: ''
        },
    });
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [rerender, setRerender] = useState(0)
    const dispatch = useDispatch()
    const [countries, setCountries] = useState<any>([])
    // const mutation = useMutation(productionCreate);
    const createData = async (data: any) => {
        data.startDate = moment(data.startDate).format('DD-MM-YYYY');
        data.endDate = moment(data.endDate).format('DD-MM-YYYY');
        const newKeyValuePairs = {
            // docType: "PROINFO",
            createdBy: "Admin",
            updatedBy: "",
            createdAt: moment().format('DD-MM-YYYY'),
            updatedAt: moment().format('DD-MM-YYYY')
        };
        const updatedData = {
            ...data,
            ...newKeyValuePairs,
        };
        setLoader(true)
        console.log(updatedData)
        axios.post(Endpoints.Create_Production_Info, updatedData).then((res: any) => {
            setLoader(false)
            if (res.data.status === "Success") {
                toast.success(res.data.message)
                swal("Success", res.data.message, "success")
                dispatch(
                    setProduction({
                        productionId: data.productionID,
                        refinedOutput: data.refinedOutput,
                        identityId: data.refineryID,
                        identityLocation: data.refineryLocation
                    })
                );
                navigate('/refineries_Storages')
            }
        })
    }
    useEffect(() => {
        setRerender(rerender + 1)
        getCountries()
    }, []);
    const getCountries = () => {
        setLoader(true)
        axios.get(Endpoints.Get_Country).then((res: any) => {
            setLoader(false)
            console.log(res.data, "res")
            setCountries(res.data)
        })
    }
    const handleChangeCountry = (event: any) => { }
    const resetForm = () => { }
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={4} className='grid_content'>
                    <img src={require('../asserts/Realgreen-Imgs/Screen0.png')} alt="Screen" className='screen_img' />

                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs sx={{ pt: '0px !important' }}>
                    <Box sx={{ display: 'flex', flexdirection: 'row' }}>
                        <form onSubmit={handleSubmit((data: any) => {
                            createData(data);

                        })}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size='small'>
                                        <TextField
                                            id="outlined-basic" label="Refinery Id" variant="outlined"
                                            size='small'
                                            fullWidth
                                            {...register(`refineryID`)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        key={rerender}
                                        size="small"
                                        options={countries}
                                        getOptionLabel={(option: any) => option.countryName}
                                        onChange={(event, newValue) => {
                                            handleChangeCountry(newValue)
                                        }}

                                        renderInput={(params) => <TextField {...params}  {...register(`refineryLocation`,)} label="Refinery Location" />}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size='small'>
                                        <TextField
                                            id="outlined-basic" label="Production Id" variant="outlined"
                                            size='small'
                                            fullWidth
                                            {...register(`productionID`)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size='small'>
                                        <TextField
                                            id="outlined-basic"
                                            label="Start Date"
                                            fullWidth
                                            size="small"
                                            sx={{ width: '100%' }}
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                ...register('startDate'),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size='small'>
                                        <TextField
                                            id="outlined-basic"
                                            label="End Date"
                                            fullWidth
                                            size="small"
                                            sx={{ width: '100%' }}
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                ...register('endDate'),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size='small'>
                                        <TextField
                                            id="outlined-basic" label="Crude Input" variant="outlined"
                                            size='small'
                                            fullWidth
                                            {...register(`crudeInput`)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">Barrels.</InputAdornment>,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size='small'>
                                        <TextField
                                            id="outlined-basic" label="Refined Output" variant="outlined"
                                            size='small'
                                            fullWidth
                                            {...register(`refinedOutput`)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">Ltrs.</InputAdornment>,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>
                            <Box display="flex" justifyContent="left" alignItems="center" sx={{ mt: 2 }}>
                                <LoadingButton
                                    // loading={loader}
                                    variant="contained"
                                    type="submit"
                                    className="submitbtn"
                                    size='small'
                                    sx={{

                                        fontWeight: 500,
                                        backgroundColor: "#555a64",
                                        color: "#fff",
                                        "& .MuiButtonBase-root:hover": {
                                            backgroundColor: "#555a64",
                                            color: "#fff",
                                        },
                                    }}
                                    onClick={(event) => resetForm()}
                                >
                                    Reset
                                </LoadingButton>

                                <LoadingButton
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                    loading={loader}
                                    color='success'

                                    sx={{ marginLeft: 2, px: 2 }} // Add margin-left for space
                                >
                                    Submit
                                </LoadingButton>
                            </Box>
                        </form>
                    </Box>
                </Grid>
            </Grid>

        </div>
    )
}
