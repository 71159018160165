import { Box, Divider, FormControl, Grid, IconButton, InputAdornment, Paper, TextField, Autocomplete, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form';
import Certificate1 from '../components/Dialog/Certificate1';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { setStepper } from '../Redux/Slices/StepperSlice';
import axios from 'axios';
import { Endpoints } from '../Enviornment/Endpoints';
import moment from 'moment';
import { toast } from 'react-toastify';
import { setProduction } from '../Redux/Slices/ProductionCreationSlice';
export default function Transportations_Two() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setStepper(5))
    getCountries()
    getFuelType()
  }, [])
  const [open, setOpen] = React.useState(false);
  const [countries, setCountries] = useState<any>([])
  const productionData = useSelector((state: any) => state.Production)
  const [originCountries, setOriginCountries] = useState<any>([])
  const [loader, setLoader] = useState(false);
  const [rerender, setRerender] = useState(0);
  const [fuelTypeList, setFuelTypeList] = useState<any>([])
  const [documnetdata, setDocumentData] = useState<any>()
  console.log(documnetdata, productionData)
  const certificateData = useSelector((state:any)=>state.Certificate)
  const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
    mode: "onChange",
    defaultValues: {
      transportationDetails: [
        {
          transferLiter: '',
          origin: '',
          destination: '',
          travelDistance: '',
          ageOfTanker: '',
          tankerWeight: '',
          fuelType: '',
          regDistStgTransID: ''
        }
      ],

    },
  });
  const { fields: railInfoFields, append: appendRailInfo, remove: removeRailInfo } = useFieldArray({
    name: "transportationDetails",
    control,
    rules: {
      required: "Enter All Details",
    },
  });
  const getCountries = () => {
    setLoader(true)
    axios.get(Endpoints.Get_Country).then((res: any) => {
      setLoader(false)
      console.log(res.data, "res")
      setCountries(res.data)
    })
    let countries: any = sessionStorage.getItem('countries')
    console.log(JSON.parse(countries))
    setOriginCountries(JSON.parse(countries))
  }
  const getFuelType = () => {
    axios.get(Endpoints.Get_Fuel_Type).then((res: any) => {
      setFuelTypeList(res.data[0].value)
    })
  }
  const createData = (data: any) => {
    const newKeyValuePairs = {
      productionID: productionData.productionId,
      regDistStgID: certificateData.regDistStgID,
      refineryID: productionData.identityId,
      // refineryLocation: productionData.identityLocation,
      createdBy: "Admin",
      updatedBy: "",
      createdAt: moment().format('DD-MM-YYYY'),
      updatedAt: moment().format('DD-MM-YYYY'),
      
    };
    const updatedData = {
      ...data,
      ...newKeyValuePairs,
    };
    console.log(updatedData, "data")
    const sumOfTransferLiter = data.transportationDetails.reduce((sum: any, item: any) => {
      return sum + parseInt(item.transferLiter, 10);
    }, 0);
    if (sumOfTransferLiter === JSON.parse(productionData.refinedOutput)) {
      console.log("Sum of transferLiter values matches totRefOpAfterLeak");
      setLoader(true)
      axios.post(Endpoints.Create_Reg_Dist_Trans, updatedData).then((res: any) => {
        setLoader(false)
        if (res.data.status === "Success") {
          toast.success(res.data.message)
          dispatch(
            setProduction({
              productionId: res.data.data.regDistStgTranstation.productionID,
              refinedOutput: res.data.data.regDistStgTranstation.totRefOpAfterLeak,
              identityId: productionData.identityId,
              identityLocation: productionData.identityLocation
            })
          );
          setDocumentData(res.data.data)
          setOpen(true)
        }
      })
    }
    else {
      alert("Sum of transferLiter values does not match totRefOpAfterLeak");
    }
  }
  const resetForm = () => {

  }
  const addPipelineInfo = (index: any) => {
    appendRailInfo({
      transferLiter: '',
      origin: '',
      destination: '',
      travelDistance: '',
      ageOfTanker: '',
      tankerWeight: '',
      fuelType: '',
      regDistStgTransID: ''
    })
  }
  const closeDialog = () => {
    setOpen(false)
  }
  const removedPipelineInfo = (index: any) => {
    removeRailInfo(index)
  }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4} className='grid_content'>
          <img src={require('../asserts/Realgreen-Imgs/Screen10.png')} alt="Screen" className='screen_img' />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs sx={{ pt: '0px !important' }}>
          <Grid container spacing={2} >
            <Grid item xs={3} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refinery Id:</Typography>&nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{productionData.identityId}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Production Id:</Typography>&nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{productionData.productionId}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refined Output:</Typography> &nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{parseFloat(productionData.refinedOutput).toLocaleString('en-IN')}&nbsp;Ltrs.</Typography>
            </Grid>
          </Grid>
          <Box>
            <form onSubmit={handleSubmit((data: any) => {
              createData(data);
            })}>
              <Box sx={{ mb: 1, mt: 2 }}>

                {railInfoFields.map((field: any, index: any) => (
                  <Paper sx={{ p: 2, my: 2 }}>
                    <Grid container spacing={2} key={index} sx={{ my: 1 }}>
                      <Grid item xs={10}>
                        <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                id="outlined-basic" label="Transporter ID / Carrier ID" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`transportationDetails.${index}.regDistStgTransID`)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                id="outlined-basic" label="Transfer Litres" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`transportationDetails.${index}.transferLiter`)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <Autocomplete
                              key={rerender}
                              size="small"
                              options={originCountries}
                              getOptionLabel={(option: any) => option.origin}
                              onChange={(event: any, newValue: any) => {
                              }}

                              renderInput={(params: any) => <TextField {...params}  {...register(`transportationDetails.${index}.origin`)} label="Origin" />}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Autocomplete
                              key={rerender}
                              size="small"
                              options={countries}
                              getOptionLabel={(option: any) => option.countryName}
                              onChange={(event, newValue) => {
                              }}

                              renderInput={(params) => <TextField {...params} {...register(`transportationDetails.${index}.destination`)} label="Destination" />}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                id="outlined-basic" label="Travel Distance" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`transportationDetails.${index}.travelDistance`)}

                                InputProps={{
                                  endAdornment: <InputAdornment position="start"> kms.</InputAdornment>,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                id="outlined-basic" label="Age of Tanker" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`transportationDetails.${index}.ageOfTanker`)}

                                InputProps={{
                                  endAdornment: <InputAdornment position="start"> Yrs.</InputAdornment>,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                id="outlined-basic" label="Tanker Weight" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`transportationDetails.${index}.tankerWeight`)}

                                InputProps={{
                                  endAdornment: <InputAdornment position="start"> Tons.</InputAdornment>,
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={4}>
                            <Autocomplete
                              key={rerender}
                              size="small"
                              options={fuelTypeList}
                              getOptionLabel={(option: any) => option}
                              onChange={(event, newValue) => {

                              }}

                              renderInput={(params) => <TextField {...params}  {...register(`transportationDetails.${index}.fuelType`)} label="Fuel Type" />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2} sx={{ margin: 'auto', textAlign: 'center' }}>
                        {index == 0 ? <IconButton onClick={() => addPipelineInfo(index)}><AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} /></IconButton> : null}
                        {index == 0 ? null : <IconButton onClick={() => removedPipelineInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}
                      </Grid>
                    </Grid>
                  </Paper>

                ))}
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <LoadingButton
                  loading={false}
                  variant="contained"
                  type="submit"
                  className="submitbtn"
                  size='small'
                  sx={{

                    fontWeight: 500,
                    backgroundColor: "#555a64",
                    color: "#fff",
                    "& .MuiButtonBase-root:hover": {
                      backgroundColor: "#555a64",
                      color: "#fff",
                    },
                  }}
                  onClick={(event) => resetForm()}
                >
                  Reset
                </LoadingButton>

                <LoadingButton
                  variant="contained"
                  size="small"
                  type="submit"
                  loading={loader}
                  color='success'
                  sx={{ marginLeft: 2 }} // Add margin-left for space
                >
                  Preview
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  size="small"
                  type="submit"
                  loading={false}
                  color='primary'
                  sx={{ marginLeft: 2 }} // Add margin-left for space
                >
                  Save As Draft
                </LoadingButton>
              </Box>
            </form>
          </Box>

        </Grid>
      </Grid>
      <div className="custom-dialog">
        {open ? (
          <Certificate1
            onOpen={open}
            onClose={closeDialog}
            status={"open"}
            currentStepCount={6}
            docData={documnetdata}
            nextStep={'outlets'}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </div>
  )
}
