import { createSlice } from "@reduxjs/toolkit";
const state: any = []
const BulkStorageSlice = createSlice({
    name: 'BulkStorageSlice',
    initialState: state,
    reducers: {
        setBulkData(state, action) {
            console.log("Payload is ...", action)
            // state.push(action.payload)
            return {
                ...state,
                ...action.payload,
            };
        }
    }
})

export const { setBulkData } = BulkStorageSlice.actions;
export default BulkStorageSlice.reducer;