import React from 'react'
import '../../Styles.css'
import Header from './Header'
import Footer from './Footer'
import Steppers from './Steppers'
import { Outlet, useLocation } from 'react-router-dom'
import { Box, Breadcrumbs, Link } from '@mui/material'
import { Global } from '../../GlobalStyles'

export default function Layout() {
    const location = useLocation()
    return (
        <div className='layout'>
            <Header />
            {location.pathname != '/emissions' && location.pathname != '/emissions_Info' &&
                <Breadcrumbs aria-label="breadcrumb" sx={{ m: 2 }}>
                    <Link underline="hover" color={location.pathname !== "/" ? 'inherit' : 'primary'} href="/" sx={{ fontFamily: Global.Robotfont, fontWeight: '500' }}>
                        New Production
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href="/material-ui/getting-started/installation/"
                        sx={{ fontFamily: Global.Robotfont, fontWeight: '500' }}
                    >
                        Drafts
                    </Link>
                </Breadcrumbs>}
            {location.pathname !== '/' && location.pathname !== '/emissions' && location.pathname != '/emissions_Info' &&
                <Steppers />}
            <Box sx={{ p: 2 }}>
                <Outlet />
            </Box>
            <Footer />
        </div>
    )
}
