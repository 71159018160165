import { createSlice } from "@reduxjs/toolkit";
const state: any = []
const ProductionCreationslice = createSlice({
    name: 'ProductionCreationslice',
    initialState: state,
    reducers: {
        setProduction(state, action) {
            console.log("Payload is ...", action)
            // state.push(action.payload)
            return {
                ...state,
                ...action.payload,
            };
        }
    }
})

export const { setProduction } = ProductionCreationslice.actions;
export default ProductionCreationslice.reducer;