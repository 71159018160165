import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import Certificate1 from '../components/Dialog/Certificate1';
import { useDispatch, useSelector } from 'react-redux';
import { Endpoints } from '../Enviornment/Endpoints';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { setStepper } from '../Redux/Slices/StepperSlice';
import { setProduction } from '../Redux/Slices/ProductionCreationSlice';
export default function Outlets() {
  const dispatch = useDispatch()
  useEffect(() => {
    getMachineryType();
    getEnergyTypeList();
    dispatch(setStepper(6))
  }, [])
  const [open, setOpen] = React.useState(false);
  const [indexes, setIndex] = React.useState<any>(0)
  const [Machineindexes, setMachineIndex] = React.useState<any>(0)
  const [machineryTypeList, setMachineryTypeList] = useState<any>([])
  const [energyTypeList, setEnergyTypeList] = useState<any>([])
  const productionData = useSelector((state: any) => state.Production)
  const [rerender, setRerender] = useState(0)
  const [loader, setLoader] = useState(false);
  const [documnetdata, setDocumentData] = useState<any>()
  const certificateData = useSelector((state: any) => state.Certificate)
  console.log(productionData)
  const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
    mode: "onChange",
    defaultValues: {
      outletID: '',
      machineryInfo: [
        {
          machineryType: '',
          ageOfmachinery: '',
          usageTime: ''
        }
      ],
      vehicleIdleTime: [
        {
          avgTime2W: '',
          avgTime3W: '',
          avgTime4W: '',
          avgTime6W: '',
          avgTime10W: '',
          avgTimeGreat10W: ''
        }
      ],
      others: [
        {
          electricity: '',
          waterConsumption: '',
          energyType: ''
        }
      ]
    },
  });
  const { fields: machineryInfoFields, append: appendMachineryInfo, remove: removeMachineryInfo } = useFieldArray({
    name: "machineryInfo",
    control,
    rules: {
      required: "Enter All Details",
    },
  });
  const { fields: vehicleInfoFields, append: appendVehicleInfo, remove: removeVehicleInfo } = useFieldArray({
    name: "vehicleIdleTime",
    control,
    rules: {
      required: "Enter All Details",
    },
  });
  const others = useFieldArray({
    name: "others",
    control,
    rules: {
      required: "Enter All Details",
    },
  });
  const getMachineryType = () => {
    axios.get(Endpoints.Get_Machinery_Type).then((res: any) => {
      setMachineryTypeList(res.data[0].value)
    })
  }
  const getEnergyTypeList = () => {
    axios.get(Endpoints.Get_Energy_Type).then((res: any) => {
      setEnergyTypeList(res.data[0].value)
    })
  }
  const createData = (data: any) => {
    const newKeyValuePairs = {
      totRefOpAfterLeak: productionData.refinedOutput,
      productionID: productionData.productionId,
      regDistStgTransID: certificateData.regDistStgID,
      createdBy: "Admin",
      updatedBy: "",
      createdAt: moment().format('DD-MM-YYYY'),
      updatedAt: moment().format('DD-MM-YYYY'),

    };
    const updatedData = {
      ...data,
      ...newKeyValuePairs,
    };
    console.log(updatedData)
    setLoader(true)
    axios.post(Endpoints.Create_Outlet, updatedData).then((res: any) => {
      setLoader(false)
      if (res.data.status === "Success") {
        toast.success(res.data.message)
        dispatch(
          setProduction({
            productionId: res.data.data.outletData.productionID,
            refinedOutput: res.data.data.outletData.totRefOpAfterLeak,
            identityId: productionData.identityId,
            identityLocation: productionData.identityLocation
          })
        );
        setDocumentData(res.data.data)
        setOpen(true)
      }
    })

  }
  const addMachineryInfo = (index: any) => {
    setMachineIndex(() => Machineindexes + 1)
    console.log(index)
    appendMachineryInfo({
      machineryType: '',
      ageOfmachinery: '',
      usageTime: ''
    })
  }
  const removedMachineryInfo = (index: any) => {
    console.log("indexes Machine Info", index)
    setMachineIndex(() => Machineindexes - 1)
    removeMachineryInfo(index)
  }
  const addVehicleIdleTime = (index: any) => {
    console.log("Add", index)
    if (index < 4) {
      setIndex(() => indexes + 1)

      appendVehicleInfo({

        avgTime2W: '',
        avgTime3W: '',
        avgTime4W: '',
        avgTime6W: '',
        avgTime10W: '',
        avgTimeGreat10W: ''

      })
    }
  }

  const removeVehicle = (index: any) => {
    console.log("Remove", index)
    setIndex(() => indexes - 1)
    removeVehicleInfo(index)
  }
  const resetForm = () => {
    // removeFuelInfo(index)
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const closeDialog = () => {
    setOpen(false)
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={4} className='grid_content'>
          <img src={require('../asserts/Realgreen-Imgs/Screen11.png')} alt="Screen" className='screen_img' />

        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs sx={{ pt: '0px !important' }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={3} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refinery Id:</Typography>&nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{productionData.identityId}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Production Id:</Typography>&nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{productionData.productionId}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Typography sx={{ color: '#666', margin: 'auto 0', fontWeight: 'bold' }}>Refined Output:</Typography> &nbsp;
              <Typography sx={{ margin: 'auto 0' }}>{productionData.refinedOutput} Ltrs.</Typography>
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit((data: any) => {
            createData(data);
            // setOpen(true);
          })}>
            <Box sx={{ mb: 1 }}>
              <FormControl fullWidth size='small'>
                <TextField
                  id="outlined-basic" label="Outlet ID" variant="outlined"
                  size='small'
                  sx={{ width: '30%' }}
                  {...register(`outletID`)}
                />

              </FormControl>
              <Typography className="grid_title" sx={{ mt: 2 }}>Machinery Info</Typography>
              {machineryInfoFields.map((fields: any, index: any) => (
                <Box key={index}>
                  {index < 5 &&
                    <Grid container spacing={2} key={index} sx={{ my: 1 }}>
                      <Grid item xs={4}>
                        <Controller
                          name={`machineryInfo.${index}.machineryType`}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Autocomplete
                              key={fields.id}
                              {...field}

                              size="small"
                              options={machineryTypeList}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => field.onChange(newValue)}
                              renderInput={(params) => (
                                <TextField {...params} label="Machinery Type" />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item >
                        <FormControl fullWidth size='small'>
                          <TextField
                            key={fields.id}
                            id="outlined-basic" label="Age of Machinery" variant="outlined"
                            size='small'
                            fullWidth
                            {...register(`machineryInfo.${index}.ageOfmachinery`)}
                            InputProps={{
                              endAdornment: <InputAdornment position="start">Yrs.</InputAdornment>,
                            }}
                          />

                        </FormControl>
                      </Grid>
                      <Grid item >
                        <FormControl fullWidth size='small'>
                          <TextField
                            key={fields.id}
                            id="outlined-basic" label="Usage Time" variant="outlined"
                            size='small'
                            fullWidth
                            {...register(`machineryInfo.${index}.usageTime`)}
                            InputProps={{
                              endAdornment: <InputAdornment position="start">Hrs.</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item >
                        {/* {index == 0 ? <IconButton onClick={() => addMachineryInfo(index)}><AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} /></IconButton> : null} */}
                        {/* {index == 0 ? null : <IconButton onClick={() => removedMachineryInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>} */}

                        {index == Machineindexes ?
                          <div>
                            {Machineindexes < 4 ?
                              <div>
                                <IconButton onClick={() => addMachineryInfo(index)}>
                                  <AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} />
                                </IconButton>
                                {index != 0 && <IconButton onClick={() => removedMachineryInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}
                              </div>
                              : <IconButton onClick={() => removedMachineryInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>
                            }
                          </div> :
                          <IconButton onClick={() => removedMachineryInfo(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}

                      </Grid>
                    </Grid>
                  }

                </Box>

              ))}
            </Box>
            <Box sx={{ mb: 1, pt: 1 }}>
              <Typography className="grid_title">Vehicle Idle Time</Typography>
              {vehicleInfoFields.map((field: any, index: any) => (
                <Box>
                  {index < 5 &&
                    <Grid container spacing={2} key={index} sx={{ my: 1 }}>
                      <Grid item xs={10}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                key={field.id}
                                id="outlined-basic" label="Average Time -2 Wheelers" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`vehicleIdleTime.${index}.avgTime2W`)}
                                InputProps={{
                                  endAdornment: <InputAdornment position="start">Mnts.</InputAdornment>,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                key={field.id}
                                id="outlined-basic" label="Average Time -3 Wheelers" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`vehicleIdleTime.${index}.avgTime3W`)}
                                InputProps={{
                                  endAdornment: <InputAdornment position="start">Mnts.</InputAdornment>,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                key={field.id}
                                id="outlined-basic" label="Average Time -4 Wheelers" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`vehicleIdleTime.${index}.avgTime4W`)}
                                InputProps={{
                                  endAdornment: <InputAdornment position="start">Mnts.</InputAdornment>,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                key={field.id}
                                id="outlined-basic" label="Average Time -6 Wheelers" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`vehicleIdleTime.${index}.avgTime6W`)}
                                InputProps={{
                                  endAdornment: <InputAdornment position="start">Mnts.</InputAdornment>,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                key={field.id}
                                id="outlined-basic" label="Average Time -10 Wheelers" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`vehicleIdleTime.${index}.avgTime10W`)}
                                InputProps={{
                                  endAdornment: <InputAdornment position="start">Mnts.</InputAdornment>,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                              <TextField
                                key={field.id}
                                id="outlined-basic" label="Average Time - >10 Wheelers" variant="outlined"
                                size='small'
                                fullWidth
                                {...register(`vehicleIdleTime.${index}.avgTimeGreat10W`)}
                                InputProps={{
                                  endAdornment: <InputAdornment position="start">Mnts.</InputAdornment>,
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        {index == indexes ?
                          <div>
                            {indexes < 4 ?
                              <div>
                                <IconButton onClick={() => addVehicleIdleTime(index)}>
                                  <AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} />
                                </IconButton>
                                {index != 0 && <IconButton onClick={() => removeVehicle(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}
                              </div>
                              : <IconButton onClick={() => removeVehicle(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>
                            }
                          </div> :
                          <IconButton onClick={() => removeVehicle(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}


                      </Grid>
                    </Grid>
                  }
                </Box>
              ))}
            </Box>
            <Box sx={{ mb: 1, pt: 1 }}>
              <Typography className="grid_title">Others</Typography>
              {others.fields.map((fields: any, index: any) => (
                <Grid container spacing={2} key={index} sx={{ my: 1 }}>
                  <Grid item xs={4}>
                    <Controller
                      name={`others.${index}.energyType`}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Autocomplete
                          key={fields.id}
                          {...field}

                          size="small"
                          options={energyTypeList}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) => field.onChange(newValue)}
                          renderInput={(params) => (
                            <TextField {...params} label="Energy Type" />
                          )}
                        />
                      )}
                    />


                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth size='small'>
                      <TextField

                        id="outlined-basic" label="Electricity" variant="outlined"
                        size='small'
                        fullWidth
                        {...register(`others.${index}.electricity`)}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">Kw.</InputAdornment>,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth size='small'>
                      <TextField
                        id="outlined-basic" label="Water Consumption" variant="outlined"
                        size='small'
                        fullWidth
                        {...register(`others.${index}.waterConsumption`)}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">Ltrs.</InputAdornment>,
                        }}
                      />
                    </FormControl>
                  </Grid>






                </Grid>
              ))}
            </Box>
            <Box display="flex" justifyContent="left" alignItems="center" sx={{ mt: 2 }}>
              <LoadingButton
                loading={false}
                variant="contained"
                type="submit"
                // className="submitbtn"
                size="small"
                sx={{
                  fontFamily: 'Roboto',
                  backgroundColor: "#555a64",
                  color: "#fff",
                  "& .MuiButtonBase-root:hover": {
                    backgroundColor: "#555a64",
                    color: "#fff",

                  },
                }}
                onClick={(event) => resetForm()}
              >
                Reset
              </LoadingButton>

              <LoadingButton

                variant="contained"
                size="small"
                type="submit"
                loading={loader}
                color='success'
                sx={{ marginLeft: 2, fontFamily: 'Roboto', }} // Add margin-left for space
              >
                Preview
              </LoadingButton>
            </Box>
          </form>
        </Grid>
      </Grid>
      <div className="custom-dialog">
        {open ? (
          <Certificate1
            onOpen={open}
            onClose={closeDialog}
            key={1}
            status={"open"}
            currentStepCount={7}
            docData={documnetdata}
            nextStep={''}
            data={productionData}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>

    </div>
  )
}
